const data = [
  {
    id: "dashboards",
    icon: "iconsminds-shop-4",
    label: "menu.dashboards",
    to: "/app/dashboards",
    subs: [
      {
        icon: "simple-icon-control-play",
        label: "menu.live",
        to: "/app/dashboards/live",
      },
      {
        icon: "simple-icon-chart",
        label: "menu.efficiency",
        to: "/app/dashboards/efficiency",
      },

      {
        icon: "simple-icon-layers",
        label: "menu.story",
        to: "/app/dashboards/story",
      },
    ],
  },
  {
    id: "track",
    icon: "iconsminds-digital-drawing",
    label: "menu.pages",
    to: "/app/tracking",
    subs: [
      {
        icon: "iconsminds-jeep",
        label: "menu.vehicles",
        to: "/app/tracking/vehicles",
      },
      // {
      //   icon: "iconsminds-mine",
      //   label: "menu.logistics",
      //   to: "/app/tracking/logistics",
      // },
      {
        icon: "iconsminds-globe-2",
        label: "menu.live-map",
        to: "/app/tracking/live-map",
      },
      {
        icon: "iconsminds-time-backup",
        label: "menu.history",
        to: "/app/tracking/history",
      },
      // {
      //   icon: "iconsminds-time-backup",
      //   label: "menu.multiple-history",
      //   to: "/app/tracking/multiple-history",
      // },
      // {
      //   icon: "iconsminds-time-backup",
      //   label: "menu.geofence-history",
      //   to: "/app/tracking/geofence-history",
      // },
      // {
      //   icon: "iconsminds-time-backup",
      //   label: "menu.geofence-analysis",
      //   to: "/app/tracking/geofence-analysis",
      // },
      {
        icon: "iconsminds-satelite-2",
        label: "menu.map-search",
        to: "/app/tracking/map-search",
      },
      // {
      //   icon: "iconsminds-location-2",
      //   label: "menu.trips",
      //   to: "/app/tracking/trips",
      // },
      {
        icon: "iconsminds-information",
        label: "day-summary.errands",
        to: "/app/tracking/errands",
      },
      {
        icon: "iconsminds-dashboard",
        label: "menu.speed-chart",
        to: "/app/tracking/speed-chart",
      },
      {
        icon: "iconsminds-dashboard",
        label: "menu.speed-chart-2",
        to: "/app/tracking/second-speed-chart",
      },
      // {
      //   icon: "iconsminds-gas-pump",
      //   label: "menu.fuel-chart",
      //   to: "/app/tracking/fuel-chart",
      // },
    ],
  },

  {
    id: "reports",
    icon: "simple-icon-calculator",
    label: "menu.reports",
    to: "/app/reports",
    subs: [
      {
        icon: "simple-icon-doc",
        label: "menu.trip",
         to: "/app/reports2/trip",
      },

      /*
      {
        icon: "simple-icon-doc",
        label: "menu.trip",
         to: "/app/reports/trip",
      }, */
      {
        icon: "simple-icon-doc",
        label: "menu.idle",
        to: "/app/reports/idle",
      },
      {
        icon: "simple-icon-doc",
        label: "menu.alert",
        to: "/app/reports/alert",
      },
      {
        icon: "simple-icon-doc",
        label: "menu.geofence-alerts",
        to: "/app/reports/geofence-alerts",
      },
      {
        icon: "simple-icon-doc",
        label: "menu.summary",
        to: "/app/reports2/summary",
      },
      /*
      {
        icon: "simple-icon-docs",
        label: "menu.vehicle-utilization",
        to: "/app/reports/vehicle-utilization",
      },
      {
        icon: "simple-icon-docs",
        label: "menu.vehicle-summary",
        to: "/app/reports/vehicle-summary",
      },*/
      {
        icon: "simple-icon-doc",
        label: "menu.driver-log",
        to: "/app/reports/driver-log",
      },
      {
        icon: "simple-icon-doc",
        label: "menu.driver-trips",
        to: "/app/reports/driver-trips",
      },
      {
        icon: "simple-icon-doc",
        label: "menu.driver-alerts",
        to: "/app/reports/driver-alerts",
      },
      {
        icon: "simple-icon-doc",
        label: "menu.driver-idling",
        to: "/app/reports/driver-idling",
      },
      {
        icon: "simple-icon-doc",
        label: "menu.driver-utilization",
        to: "/app/reports/driver-utilization",
      },
      {
        icon: "simple-icon-doc",
        label: "menu.live",
        to: "/app/reports/live",
      },
      {
        icon: "simple-icon-doc",
        label: "menu.passenger-changes",

        to: "/app/reports/passengers",
      },
      {
        icon: "simple-icon-doc",
        label: "menu.log-access",
        to: "/app/reports/log-access",
      },
    ],
  },
  {
    id: "reports2",
    icon: "simple-icon-calculator",
    label: "menu.reports",
    to: "/app/reports2",
    subs: [
      {
        icon: "simple-icon-doc",
        label: "menu.trip",
         to: "/app/reports2/trip",
      },
     
      {
        icon: "simple-icon-doc",
        label: "menu.idle",
        to: "/app/reports2/idle",
      },
      {
        icon: "simple-icon-doc",
        label: "menu.overspeed",
        to: "/app/reports2/overspeed",
      },
      {
        icon: "simple-icon-doc",
        label: "menu.summary",
        to: "/app/reports2/summary",
      },
      {
        icon: "simple-icon-doc",
        label: "menu.geofence",
        to: "/app/reports2/geofence",
      },
      {
        icon: "simple-icon-doc",
        label: "menu.restricted-geofence",
        to: "/app/reports2/restricted-geofence",
      },

      {
        icon: "simple-icon-doc",
        label: "menu.live",
        to: "/app/reports/live",
      },
       /*
      {
        icon: "simple-icon-doc",
        label: "menu.alert",
        to: "/app/reports2/alert",
      },
      {
        icon: "simple-icon-doc",
        label: "menu.geofence-alerts",
        to: "/app/reports2/geofence-alerts",
      },
      {
        icon: "simple-icon-docs",
        label: "menu.vehicle-utilization",
        to: "/app/reports2/vehicle-utilization",
      },
      {
        icon: "simple-icon-docs",
        label: "menu.vehicle-summary",
        to: "/app/reports2/vehicle-summary",
      },
      {
        icon: "simple-icon-doc",
        label: "menu.driver-trips",
        to: "/app/reports2/driver-trips",
      },
      {
        icon: "simple-icon-doc",
        label: "menu.driver-alerts",
        to: "/app/reports2/driver-alerts",
      },
      {
        icon: "simple-icon-doc",
        label: "menu.driver-idling",
        to: "/app/reports2/driver-idling",
      },
      {
        icon: "simple-icon-doc",
        label: "menu.driver-utilization",
        to: "/app/reports2/driver-utilization",
      },
      {
        icon: "simple-icon-doc",
        label: "menu.live",
        to: "/app/reports2/live",
      },
      {
        icon: "simple-icon-doc",
        label: "menu.passenger-changes",

        to: "/app/reports2/passengers",
      },
      {
        icon: "simple-icon-doc",
        label: "menu.log-access",
        to: "/app/reports2/log-access",
      },*/
    ],
  },
  {
    id: "Drill Downs",
    icon: "iconsminds-monitor-analytics",
    label: "menu.drill-downs",
    to: "/app/drill-downs",
    subs: [
      {
        icon: "iconsminds-digital-drawing",
        label: "menu.drill-downs-geofence",
        to: "/app/drill-downs/geofence",
      },
      {
        icon: "iconsminds-digital-drawing",
        label: "menu.drill-downs-day-summary",
        to: "/app/drill-downs/day-summary",
      },
    ],
  },

  {
    id: "settings",
    icon: "simple-icon-settings",
    label: "menu.settings",
    to: "/app/settings",
    subs: [
      {
        icon: "iconsminds-car",
        label: "menu.vehicle-master",
        to: "/app/settings/vehicle-master",
      },
      {
        icon: "iconsminds-business-mens",
        label: "menu.geofence-settings",
        to: "/app/settings/geofences",
      },
      {
        icon: "iconsminds-home-3",
        label: "menu.company-master",
        to: "/app/settings/profile-master",
      },
      {
        icon: "iconsminds-king-2",
        label: "menu.driver-settings",
        to: "/app/settings/driver-settings",
      },
      {
        icon: "iconsminds-business-mens",
        label: "menu.user-master",
        to: "/app/settings/user-master",
      },
      {
        icon: "iconsminds-male-female",
        label: "menu.passenger",
        to: "/app/settings/passenger",
      },
      {
        icon: "iconsminds-data-center",
        label: "menu.groups",
        to: "/app/settings/groups",
      },
    ],
  },
  // {
  //   id: "support",
  //   icon: "simple-icon-support",
  //   label: "menu.support",
  //   to: "/app/support",
  //   subs: [
  //     {
  //       icon: "iconsminds-receipt-4",
  //       label: "menu.invoices",
  //       to: "/app/support/invoices",
  //     },
  //     {
  //       icon: "iconsminds-credit-card",
  //       label: "menu.jobcards",
  //       to: "/app/support/jobcards",
  //     },
  //     {
  //       icon: "simple-icon-refresh",
  //       label: "menu.services",
  //       to: "/app/support/services",
  //     },
  //     {
  //       icon: "simple-icon-star",
  //       label: "menu.tickets",
  //       to: "/app/support/tickets",
  //     },
  //     {
  //       icon: "simple-icon-event",
  //       label: "menu.visits",
  //       to: "/app/support/visits",
  //     },
  //   ],
  // },
];
export default data;
