import React, { Component } from "react";
import { Button, Row, Card, CardBody, Table } from "reactstrap";
import { connect } from "react-redux";
import moment from "moment";
import GoogleMap from "google-map-react";
import { createIntl } from "react-intl";

import { Colxx } from "../../components/common/CustomBootstrap";
import { authApiAccessCheck } from "../../redux/actions";
import MapModel from "../../components/MapModel";
import DateRangePicker from "../../components/DateRangePicker";
import VehicleSelectWithBatch from "../../components/VehicleSelectWithBatch";
import LocationName from "../../components/LocationName";
import { NotificationManager } from "../../components/common/react-notifications";
import { AppHelperDateToDBString } from "../../helpers/AppHelper";

import {
  GetBluePlateLocation,
  VehiclesGetDaySummary,
} from "../../api-tasks-analytics/map-search";
import MapPOINew from "../../map-helper/map-poi-new";
import IntlMessages from "../../helpers/IntlMessages";
import AppLocale from "../../lang";

import MapDrawing from "../../map-helper/map-drawing";
import MapPolygon from "../../map-helper/map-polygon";
import {
  GeofenceAdd,
  GeofenceUpdate,
  GeofenceDelete,
} from "../../api-tasks/geofence-changes";
import { geofenceSelectRequest } from "../../redux/actions";

import {
  FavoritePlaceAdd,
  FavoritePlacesDelete,
} from "../../api-tasks/favorite-places";
import { favoritePlacesSelectRequest } from "../../redux/actions";
import MapFavoritePlaces from "../../map-helper/map-favorite-places";

const pagingLimit = 20;

class MapSearchContainer extends Component {
  constructor(props) {
    super();
    this.mapDrawing = null;

    this.state = {
      inputGeopoint: "",
      inputRadius: "500",
      inputBuildingNumber: "",
      inputStreet: "",
      inputZone: "",
      selectedVehicles: [],
      loading: false,
      filteredRows: [],
      allRows: [],
      totalCount: 0,
      limit: pagingLimit,
      skip: 0,
      mapModleGeo: "",
      mapModlePlace: "",
      mapModelOpen: false,
      bluePlateLoading: false,
      showAllRows: false,
      favoritePlacesVisibility: true,
    };
  }

  componentWillUnmount() {}

  componentDidMount() {
    this.setState({
      allRows: [],
      filteredRows: [],
      mapModleGeo: "",
      mapModlePlace: "",
      mapModelOpen: false,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.geofenceAll !== this.props.geofenceAll) {
      this.generateGeofencePoints();
    }

    if (prevProps.favoritePlacesAll !== this.props.favoritePlacesAll) {
      this.generateFavoritePlaces();
    }
  }

  generateGeofencePoints = () => {
    const userDetails = this.props.userDetails || {};
    const userRole = userDetails.role || 3;

    // if user no geofence 
    if (userRole === 3) {
      return ; 
    }
    
    if (!this.state.map || !this.state.maps || !this.props.geofenceAll) {
      this.setState({ geofencePoints: [] });
      return;
    }

    const geofenceAll = this.props.geofenceAll || [];
    MapPolygon.updatePolygons(
      geofenceAll,
      this.state.map,
      this.state.maps,
      this.onGeofenceUpdate,
      this.onGeofenceDelete
    );

    // TODO: R1: Load geofence here
    this.setState({
      geofencePoints: geofenceAll.map((x) => {
        return {
          ...x,
          vehicles: [],
        };
      }),
    });
  };

  generateFavoritePlaces = () => {
    if (!this.state.map || !this.state.maps || !this.props.favoritePlacesAll) {
      this.setState({ favoritePlacePoints: [] });
      return;
    }

    const favoritePlacesAll = this.props.favoritePlacesAll || [];

    MapFavoritePlaces.updateMarkers(
      favoritePlacesAll,
      this.state.map,
      this.state.maps,
      this.onFavoritePlaceDelete
    );

    this.setState({ favoritePlacePoints: favoritePlacesAll });
  };

  onGeofenceUpdate = (id, title, speed) => {
    const user = this.props.user;

    const userDetails = this.props.userDetails;

    if(userDetails.roleText === "User" ){

      NotificationManager.error(
        'Geofence can update only by Owner or Admin',
        "Permission denied",
        5000,
        null,
        null,
        ""
      );
      return;
    }
    
    GeofenceUpdate(user.userToken, id, title, speed).then((response) => {
      if (response) {
        this.setState({ loading: false });
        if (response) {
          if (response.status === "success") {
            this.props.geofenceSelectRequest(this.props.history);
            NotificationManager.success(
              response.message,
              "Success",
              3000,
              null,
              null,
              ""
            );
          } else {
            // console.error("geofence.add", response);
            NotificationManager.warning(
              response.message,
              response.status,
              5000,
              null,
              null,
              ""
            );
          }
        }
      }
    });
  };

  onGeofenceDelete = (id) => {
    const user = this.props.user;

    const userDetails = this.props.userDetails;

    if(userDetails.roleText === "User" ){

      NotificationManager.error(
        'Geofence can delete only by Owner or Admin',
        "Permission denied",
        5000,
        null,
        null,
        ""
      );
      return;
    }

    GeofenceDelete(user.userToken, id).then((response) => {
      if (response) {
        this.setState({ loading: false });
        if (response) {
          if (response.status === "success") {
            this.props.geofenceSelectRequest(this.props.history);
            NotificationManager.success(
              response.message,
              "Success",
              3000,
              null,
              null,
              ""
            );
          } else {
            // console.error("geofence.add", response);
            NotificationManager.warning(
              response.message,
              response.status,
              5000,
              null,
              null,
              ""
            );
          }
        }
      }
    });
  };

  onGeofenceSelect = (item) => {
    MapPolygon.toggle(item.autoid);
  };

  onFavoritePlacesSelectAll = (visibility) => {
    MapFavoritePlaces.showAll(visibility);
  };

  onFavoritePlacesSelect = (item) => {
    MapFavoritePlaces.toggle(item.autoid, true);
  };

  toggleLarge = () => {
    this.setState((prevState) => ({
      mapModelOpen: !prevState.mapModelOpen,
    }));
  };

  showModel = (geo, place) => {
    this.setState({
      mapModleGeo: geo,
      mapModlePlace: place,
      mapModelOpen: true,
    });
  };

  findVehicleName = (item) => {
    const ignition = item || {};
    if (ignition.vehicleId && ignition.vehicleId > 0) {
      if (this.props.vehiclesAll && this.props.vehiclesAll.length) {
        const foo = this.props.vehiclesAll.filter(function (value) {
          return value.autoid === ignition.vehicleId;
        });

        if (foo && foo.length) {
          return `${foo[0].plate_number} ${foo[0].fleet_number} ${foo[0].model} ${foo[0].typeText}`;
        }
      }
    }
    return "";
  };

  onBluePlateLocation = () => {
    const user = this.props.user;

    const building = this.state.inputBuildingNumber;
    const street = this.state.inputStreet;
    const zone = this.state.inputZone;

    if (!building || !street || !zone) {
      NotificationManager.error(
        "Building, Street and Zone numbers are required!",
        "ERROR",
        3000,
        null,
        null,
        ""
      );
      return;
    }

    this.setState({ bluePlateLoading: true });

    GetBluePlateLocation(user.userToken, building, street, zone).then(
      (response) => {
        this.setState({
          bluePlateLoading: false,
        });

        if (response && response.status === "success") {
          // console.log('the GetBluePlateLocation ', response.geo);
          this.state.poiItem.findLocation(response.geo);
        } else {
          NotificationManager.error(
            "Building not found!",
            "ERROR",
            3000,
            null,
            null,
            ""
          );
        }
      }
    );
  };

  onDateRangeGo = (limit, skip) => {
    if (!this.state.selectedVehicles || !this.state.selectedVehicles.length) {
      NotificationManager.error(
        "Please select vehicle",
        "ERROR",
        3000,
        null,
        null,
        ""
      );

      return;
    }

    const user = this.props.user;
    const startTime = AppHelperDateToDBString(this.state.startTime.toDate());
    const endTime = AppHelperDateToDBString(this.state.endTime.toDate());
    const geo = this.state.inputGeopoint;
    const radius = this.state.inputRadius;

    const vehicleIds = this.state.selectedVehicles.map((el) => {
      return el.key;
    });

    this.setState({ loading: true });

    VehiclesGetDaySummary(
      user.userToken,
      vehicleIds,
      startTime,
      endTime,
      geo,
      radius
    ).then((response) => {
      this.setState({
        loading: false,
        allRows: [],
        totalCount: 0,
      });

      if (response && Array.isArray(response) && !response.error) {
        const rows = [];
        for (let index = 0; index < response.length; index++) {
          const itemDay = response[index];

          const summaryDay = moment(itemDay.summaryDay);
          const summaryDayText = moment(itemDay.summaryDay).format(
            "ddd MMM-DD"
          );
          const vehicleName = this.findVehicleName(itemDay);

          if (itemDay.driven) {
            itemDay.driven.forEach((el) => {
              rows.push({
                type: "Driven",
                summaryDay,
                summaryDayText,
                vehicleName,
                geo: el.geo,
                place: el.place,
                time: el.time,
                timeText: moment(el.time).format("hh:mm:ss A"),
                diff: el.diff,
                diffText: el.diff.toFixed(2),
              });
            });
          }

          if (itemDay.stops) {
            itemDay.stops.forEach((el) => {
              rows.push({
                type: "Stops",
                summaryDay,
                summaryDayText,
                vehicleName,
                geo: el.geo,
                place: el.place,
                time: el.startTime,
                timeText: moment(el.startTime).format("hh:mm:ss A"),
                diff: el.diff,
                diffText: el.diff.toFixed(2),
              });
            });
          }

          if (itemDay.visited) {
            itemDay.visited.forEach((el) => {
              rows.push({
                type: "Visited",
                summaryDay,
                summaryDayText,
                vehicleName,
                geo: el.geo,
                place: el.place,
                time: el.startTime,
                timeText: moment(el.startTime).format("hh:mm:ss A"),
                diff: el.diff,
                diffText: el.diff.toFixed(2),
              });
            });
          }
        }

        this.setState({
          allRows: rows,
          totalCount: response.count,
          touched: true,
        });
      }
    });
  };

  handleChangeDateRange = (startTime, endTime) => {
    this.setState({
      startTime,
      endTime,
      limit: pagingLimit,
      skip: 0,
      allRows: [],
    });
    if (this.state.selectedVehicles.length) {
      /*
      setTimeout(() => {
        this.onDateRangeGo(this.state.limit, this.state.skip);
      }, 10);*/
    }
  };

  handleOnSelectedVehicleChange = (selectedVehicles) => {
    this.setState({ selectedVehicles });

    this.setState({
      selectedVehicles,
      allRows: [],
      limit: pagingLimit,
      skip: 0,
    });

    /*
    setTimeout(() => {
      this.onDateRangeGo(this.state.limit, this.state.skip);
    }, 10);
    */
  };

  POIItem_onDissmiss = (item) => {
    console.log("POIItem_onDissmiss called");
  };

  MapPOINew_onChange = (geo_code, radius) => {
    this.setState({
      inputGeopoint: geo_code,
      inputRadius: radius.toFixed(2),
    });
  };

  onAddNewPOI = () => {
    if (this.state.poiItem) {
      this.state.poiItem.dismiss();
    }

    let _pi23 = MapPOINew.init(
      this.state.map,
      this.state.maps,
      this.MapPOINew_onChange,
      this.POIItem_onDissmiss
    );
    this.setState({ poiItem: _pi23 });
  };

  onMapLoaded = (map, maps) => {
    this.setState({
      mapsLoaded: true,
      map: map,
      maps: maps,
    });

    this.mapDrawing = MapDrawing.init(
      map,
      maps,
      this.OnMapDrawingCompleted,
      this.OnMapDrawingAddNew,
      this.OnMapDrawingAddNew_FavoritePlace
    );

    setTimeout(() => {
      this.onAddNewPOI();
    }, 10);

    this.generateGeofencePoints();
    this.generateFavoritePlaces();
  };

  OnMapDrawingAddNew_FavoritePlace = (title, geo, radius, typeText, type) => {
    // const points = geo;
    const user = this.props.user;
    FavoritePlaceAdd(user.userToken, title, geo, radius, typeText, type).then(
      (response) => {
        if (response) {
          this.setState({ loading: false });

          if (response) {
            if (response.status === "success") {
              this.props.favoritePlacesSelectRequest(this.props.history);

              NotificationManager.success(
                response.message,
                "Success",
                3000,
                null,
                null,
                ""
              );
            } else {
              NotificationManager.warning(
                response.message,
                response.status,
                5000,
                null,
                null,
                ""
              );
            }
          }
        }
      }
    );
  };

  onFavoritePlaceDelete = (id) => {
    const user = this.props.user;
    FavoritePlacesDelete(user.userToken, id).then((response) => {
      if (response) {
        this.setState({ loading: false });
        if (response) {
          if (response.status === "success") {
            this.props.favoritePlacesSelectRequest(this.props.history);
            NotificationManager.success(
              response.message,
              "Success",
              3000,
              null,
              null,
              ""
            );
          } else {
            NotificationManager.warning(
              response.message,
              response.status,
              5000,
              null,
              null,
              ""
            );
          }
        }
      }
    });
  };

  OnMapDrawingCompleted = (pathPoints) => {
    // console.log("log ", this.props.geofence);
  };

  OnMapDrawingAddNew = (name, speed, coordinates) => {
    const points = coordinates.join("");
    const user = this.props.user;

    const userDetails = this.props.userDetails;

    if(userDetails.roleText === "User" ){

      NotificationManager.error(
        'Geofence can add only by Owner or Admin',
        "Permission denied",
        5000,
        null,
        null,
        ""
      );
      return;
    }

    GeofenceAdd(user.userToken, name, points, speed).then((response) => {
      if (response) {
        this.setState({ loading: false });
        if (response) {
          if (response.status === "success") {
            this.props.geofenceSelectRequest(this.props.history);

            NotificationManager.success(
              response.message,
              "Success",
              3000,
              null,
              null,
              ""
            );
          } else {
            // console.error("geofence.add", response);
            NotificationManager.warning(
              response.message,
              response.status,
              5000,
              null,
              null,
              ""
            );
          }
        }
      }
    });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    // console.log("chaneg ", [e.target.name], e.target.value);
    if (e.target.name === "inputGeopoint") {
      if (this.state.poiItem) {
        this.state.poiItem.findLocation(e.target.value);
      }
    }
  };

  handleNumberOnlyChange = (e) => {
    const re = /^[0-9\b]+$/;

    if (e.target.value === "" || re.test(e.target.value)) {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  render() {
    const favoritePlacesAll = this.state.favoritePlacePoints || [];
    const geofenceAll = this.state.geofencePoints || [];

    const currentAppLocale = AppLocale[this.props.locale];

    const intl = createIntl({
      locale: currentAppLocale.locale,
      defaultLocale: "en",
      messages: currentAppLocale.messages,
    });

    window.localsss = intl;
    const showRows =
      this.state.allRows.length > 10 && !this.state.showAllRows
        ? this.state.allRows.slice(0, 10)
        : this.state.allRows;

    return (
      <React.Fragment>
        <MapModel
          geo={this.state.mapModleGeo}
          place={this.state.mapModlePlace}
          isOpen={this.state.mapModelOpen}
          closeButton={
            <Button
              color="primary"
              outline
              size="xs"
              className="mb-2"
              onClick={this.toggleLarge}
            >
              close
            </Button>
          }
          toggle={this.toggleLarge}
        />

        <Row className="mb-1">
          <Colxx xxs="12">
            <Card>
              <CardBody className={this.state.loading ? "wait" : ""}>
                <Row>
                  <Colxx xl="6" lg="6" md="6" sm="12">
                    <Row>
                      <Colxx xl="6" lg="6" md="6" sm="12">
                        <div className="form-group">
                          <label className="title">
                            <IntlMessages id="tracking.geo-point" />
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="inputGeopoint"
                            required
                            onChange={this.handleChange}
                            value={this.state.inputGeopoint}
                            placeholder="25.291082, 51.533403 "
                          />
                        </div>
                      </Colxx>

                      <Colxx xl="6" lg="6" md="6" sm="12">
                        <div className="form-group">
                          <label className="title">
                            <IntlMessages id="tracking.radius" />
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="inputRadius"
                            required
                            onChange={this.handleChange}
                            value={this.state.inputRadius}
                            placeholder="500"
                            readOnly
                          />
                        </div>
                      </Colxx>
                    </Row>

                    <DateRangePicker
                      onChange={this.handleChangeDateRange}
                      startTime={moment().startOf("month")}
                      suggestions="months"
                      dateOnly={true}
                    />

                    <VehicleSelectWithBatch
                      classNamePrefix="repo-sel"
                      onChange={this.handleOnSelectedVehicleChange}
                      dateOnly={true}
                    />

                    <p>
                      {" "}
                      <IntlMessages id="tracking.choose-maximum-one-week" />{" "}
                    </p>
                  </Colxx>
                  <Colxx xl="6" lg="6" md="6" sm="12">
                    <Row>
                      <Colxx xl="4" lg="4" md="4" sm="12">
                        <div className="form-group">
                          <label className="title">
                            {" "}
                            <IntlMessages id="tracking.building-no" />{" "}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="inputBuildingNumber"
                            required
                            onChange={this.handleNumberOnlyChange}
                            value={this.state.inputBuildingNumber}
                            placeholder={intl.formatMessage({
                              id: "tracking.blue-plate-building-no",
                            })}
                          />
                        </div>
                      </Colxx>

                      <Colxx xl="3" lg="3" md="3" sm="12">
                        <div className="form-group">
                          <label className="title">
                            <IntlMessages id="tracking.street" />
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="inputStreet"
                            required
                            onChange={this.handleNumberOnlyChange}
                            value={this.state.inputStreet}
                            placeholder={intl.formatMessage({
                              id: "tracking.blue-plate-street-no",
                            })}
                          />
                        </div>
                      </Colxx>

                      <Colxx xl="3" lg="3" md="3" sm="12">
                        <div className="form-group">
                          <label className="title">
                            <IntlMessages id="tracking.zone" />
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="inputZone"
                            required
                            onChange={this.handleNumberOnlyChange}
                            value={this.state.inputZone}
                            placeholder={intl.formatMessage({
                              id: "tracking.blue-plate-zone-no",
                            })}
                          />
                        </div>
                      </Colxx>

                      <Colxx xl="2" lg="2" md="2" sm="12">
                        <Button
                          size="xs"
                          className="mt-3"
                          color="secondary"
                          outline
                          onClick={this.onBluePlateLocation}
                        >
                          {this.state.bluePlateLoading ? (
                            <span className="spinner d-inline-block">
                              <span className="bounce1" />
                              <span className="bounce2" />
                              <span className="bounce3" />
                            </span>
                          ) : (
                            <IntlMessages id="tracking.find" />
                          )}
                        </Button>
                      </Colxx>
                    </Row>

                    <div style={{ height: "418px" }} className="w-100">
                      <GoogleMap
                        yesIWantToUseGoogleMapApiInternals={true}
                        bootstrapURLKeys={{
                          key: "AIzaSyAKjw_wuep7sJmjM6tKylxLr6Yo_bHUxnw",
                          libraries: ["drawing"].join(","),
                        }}
                        defaultCenter={[25.273217, 51.414257]}
                        defaultZoom={8}
                        options={{ mapTypeControl: true }}
                        onGoogleApiLoaded={({ map, maps }) => {
                          this.onMapLoaded(map, maps);
                        }}
                      ></GoogleMap>
                    </div>
                    <Button
                      size="xs"
                      color="secondary"
                      outline
                      onClick={this.onAddNewPOI}
                    >
                      <IntlMessages id="tracking.marker" />
                    </Button>

                    <div className="mt-2 mb-2">
                      <strong className="text-muted">
                        <IntlMessages id="common.geofence" />
                      </strong>
                      <br />
                      {geofenceAll.map((item, index) => {
                        return (
                          <Button
                            outline
                            color="primary"
                            size="xs"
                            key={index}
                            className="mr-1 mb-1"
                            onClick={() => this.onGeofenceSelect(item)}
                          >
                            {item.title}
                          </Button>
                        );
                      })}
                      {(favoritePlacesAll.length ? {} : null) && (
                        <div className="mt-2 mb-2">
                          <strong className="text-muted">
                            Favorite Places
                          </strong>
                          <Button
                            className="ml-1"
                            size="xs"
                            onClick={() => {
                              this.onFavoritePlacesSelectAll(
                                this.state.favoritePlacesVisibility
                              );
                              this.setState({
                                favoritePlacesVisibility:
                                  !this.state.favoritePlacesVisibility,
                              });
                            }}
                          >
                            {this.state.favoritePlacesVisibility ? (
                              <IntlMessages id="tracking.show-all" />
                            ) : (
                              <IntlMessages id="tracking.hide-all" />
                            )}
                          </Button>
                          <br />
                          {favoritePlacesAll.map((item, index) => {
                            return (
                              <Button
                                outline
                                color="primary"
                                size="xs"
                                key={index}
                                className="mr-1 mt-1"
                                onClick={() =>
                                  this.onFavoritePlacesSelect(item)
                                }
                              >
                                {item.title}
                              </Button>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </Colxx>

                  <Colxx xxs="2">
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() =>
                        this.onDateRangeGo(this.state.limit, this.state.skip)
                      }
                    >
                      {this.state.loading ? (
                        <span className="spinner d-inline-block">
                          <span className="bounce1" />
                          <span className="bounce2" />
                          <span className="bounce3" />
                        </span>
                      ) : (
                        <IntlMessages id="common.search" />
                      )}
                    </Button>
                  </Colxx>
                </Row>
              </CardBody>
            </Card>
          </Colxx>
        </Row>

        <Row>
          <Colxx xxs="12">
            <Card>
              <CardBody>
                <Table responsive hover bordered striped>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>
                        <IntlMessages id="common.vehicle" />
                      </th>
                      <th>
                        <IntlMessages id="tracking.day" />
                      </th>
                      <th>
                        <IntlMessages id="common.time" />
                      </th>
                      <th>
                        <IntlMessages id="tracking.place" />
                      </th>

                      <th>
                        <IntlMessages id="tracking.type" />
                      </th>
                      <th>
                        <IntlMessages id="tracking.diff" />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {showRows.map((item, i) => (
                      <tr key={i}>
                        <th scope="row">{i + 1}</th>
                        <td>{item.vehicleName}</td>
                        <td>{item.summaryDayText}</td>
                        <td>{item.timeText}</td>
                        <td>
                          <LocationName
                            place={item.place}
                            geo={item.geo}
                            objectItem={item}
                            onClick={() => this.showModel(item.geo, item.place)}
                          />
                        </td>

                        <td>{item.type}</td>
                        <td>{item.diffText}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>

                {this.state.allRows.length > 10 && !this.state.showAllRows ? (
                  <Button
                    size="sm"
                    outline
                    color="dark"
                    className="w-100"
                    onClick={() => {
                      this.setState({ showAllRows: true });
                    }}
                  >
                    Show all {this.state.allRows.length} rows
                  </Button>
                ) : (
                  ""
                )}

                {!this.state.allRows.length ? (
                  <p>
                    <IntlMessages id="tracking.nothing-to-show" />
                  </p>
                ) : (
                  ""
                )}
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  vehicles,
  authUser,
  settings,
  geofence,
  favoritePlaces,
}) => {
  const { all } = vehicles;
  const { user, details } = authUser;
  const { locale } = settings;
  const { items } = geofence;
  const favoritePlacesAll = favoritePlaces.items || [];
  return {
    vehiclesAll: all,
    user,
    locale,
    geofenceAll: items,
    favoritePlacesAll,
    userDetails: details,
  };
};

export default connect(mapStateToProps, {
  authApiAccessCheck,
  geofenceSelectRequest,
  favoritePlacesSelectRequest,
})(MapSearchContainer);
