/* eslint-disable no-mixed-operators */
/* eslint-disable no-loop-func */
import React, { Component, Fragment } from "react";
import GoogleMap from "google-map-react";
import {
  Row,
  Button,
  Badge,
  Table,
  ButtonGroup,
  Progress,
  ButtonDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Modal,
  ModalBody,
} from "reactstrap";
import { connect } from "react-redux";
import moment from "moment";
import Slider from "rc-slider";

import IntlMessages from "../../helpers/IntlMessages";
import { NotificationManager } from "../../components/common/react-notifications";
import { Colxx } from "../../components/common/CustomBootstrap";

import MapDrawing from "../../map-helper/map-drawing";
import MapPolygon from "../../map-helper/map-polygon";
import {
  GeofenceAdd,
  GeofenceUpdate,
  GeofenceDelete,
} from "../../api-tasks/geofence-changes";
import { geofenceSelectRequest } from "../../redux/actions";
import {
  FavoritePlaceAdd,
  FavoritePlacesDelete,
} from "../../api-tasks/favorite-places";
import { favoritePlacesSelectRequest } from "../../redux/actions";
import MapFavoritePlaces from "../../map-helper/map-favorite-places";
import { authApiAccessCheck } from "../../redux/actions";
import {
  AppHelperDateToDBString,
  AppHelperDurationAsHHmm,
} from "../../helpers/AppHelper";

import { SliderTooltip } from "../../components/common/SliderTooltips";

const VEHICLE_ICON_OFF = "/map-assets/gray-dot.png";
const VEHICLE_ICON_RUNNING = "/map-assets/pickup_running.png";
const VEHICLE_ICON_IDLE = "/map-assets/red-dot.png";

const MILLISECONDS_10MIN = 600000;
const MAP_HEIGHT = "548px";
function geo2LatLng(geo) {
  const splits = geo.split(",");
  return {
    lat: parseFloat(splits[0]),
    lng: parseFloat(splits[1]),
  };
}

const sliderHandle = (props) => {
  const { value, dragging, index, offset, ...restProps } = props;
  const positionStyle = {
    backgroundColor: "#red !important",
  };
  return (
    <Fragment key={index}>
      <Slider.Handle
        className="btn btn-secondary btn-xs bg-primary"
        style={positionStyle}
        value={value}
        offset={offset}
        {...restProps}
      />
    </Fragment>
  );
};

const playSpeedOptions = [
  { label: "Normal", value: 1 },
  { label: "10sec", value: 10 },
  { label: "30sec", value: 30 },
  { label: "1min", value: 60 },
  { label: "3min", value: 180 },
  { label: "5min", value: 300 },
  { label: "10min", value: 600 },
  { label: "15min", value: 900 },
  { label: "30min", value: 1800 },
  { label: "1hrs", value: 3600 },
];

// ---------Geofence functions starts here ------------------------
function trim(s, c) {
  // eslint-disable-next-line no-param-reassign
  if (c === "]") c = "\\]";
  // eslint-disable-next-line no-param-reassign
  if (c === "\\") c = "\\\\";
  return s.replace(new RegExp(`^[${c}]+|[${c}]+$`, "g"), "");
}

function extractFence(fence) {
  const localFence = fence;
  let dataString = trim(localFence, ")");
  dataString = trim(dataString, "(");

  const dataArr = dataString.split(")(");
  const geoArr = [];
  let foo;
  dataArr.forEach((e) => {
    foo = e.split(",");
    geoArr.push([parseFloat(foo[0]), parseFloat(foo[1])]);
  });

  return geoArr;
}

function isPolyInside(point, vs) {
  // ray-casting algorithm based on
  // http://www.ecse.rpi.edu/Homepages/wrf/Research/Short_Notes/pnpoly.html

  const x = point[0];
  const y = point[1];

  let inside = false;
  // eslint-disable-next-line no-plusplus
  for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
    const xi = vs[i][0];
    const yi = vs[i][1];
    const xj = vs[j][0];
    const yj = vs[j][1];

    // eslint-disable-next-line no-mixed-operators
    const intersect =
      yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;

    if (intersect) inside = !inside;
  }

  return inside;
}

function getGeofenceWatcher() {
  return {
    inStatus: 0,
    anyChange(status) {
      if (this.inStatus === 0) {
        this.inStatus = status ? 2 : 1;
        return false;
      }
      if (this.inStatus !== (status ? 2 : 1)) {
        this.inStatus = status ? 2 : 1;
        return true;
      }
      this.inStatus = status ? 2 : 1;
      return false;
    },
  };
}

function getFencePlan(geofences) {
  if (geofences && !geofences.length) {
    return null;
  }

  const fenceEx = geofences.map((x) => ({
    ...x,
    liveStatus: null,
    isSpeeding: false,
    speedingItem: {},
    watcher: getGeofenceWatcher(),
    boundariesPoints: extractFence(x.points),
  }));

  return fenceEx;
}

// ---------Geofence functions ends here ------------------------

class GeofenceHistoryMap extends Component {
  constructor(props) {
    super();

    this.mapDrawing = null;
    this.seekBarInterval = null;

    this.state = {
      mapsLoaded: false,
      map: null,
      maps: null,
      geofencePoints: [],
      favoritePlacePoints: [],
      displayVehicles: [],
      seekBarMin: 0,
      seekBarMax: 0,
      seekBarValue: 0,
      seekBarDuration: moment.duration(),
      startTime: moment(),
      endTime: moment(),
      vehicleMarkers: [],
      fetchDataProgress: 0,
      filteredVehicles: [],
      searchText: "",
      playSpeedValue: 1,
      playBtnText: "Play",
      isOpenGDDPlaySpeed: false,
      selectedPageText: "Normal",
      wayLine: null,
      loadingModalIsOpen: false,
    };
  }

  componentWillUnmount() {}

  componentDidMount() {
    this.handlePlaySpeedChange(playSpeedOptions[2]);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.selectedGeofence !== this.props.selectedGeofence &&
      this.props.selectedGeofence.autoid
    ) {
      MapPolygon.fitBounds(this.props.selectedGeofence.autoid);
    }

    if (prevProps.geofenceAll !== this.props.geofenceAll) {
      this.generateGeofencePoints();
    }

    if (prevProps.favoritePlacesAll !== this.props.favoritePlacesAll) {
      this.generateFavoritePlaces();
    }

    if (prevProps.displayVehicles !== this.props.displayVehicles) {
      const duration = moment.duration(
        this.props.endTime.diff(this.props.startTime)
      );

      this.state.displayVehicles.forEach((v) => {
        if (v.mapMarker) {
          v.mapMarker.setMap(null);
        }
      });

      this.setState({
        displayVehicles: this.props.displayVehicles,
        startTime: this.props.startTime,
        endTime: this.props.endTime,
        seekBarMin: 0,
        seekBarMax: duration.asSeconds(),
        seekBarDuration: duration,
        fetchDataProgress: 0,
      });

      this.searchHandler(null, this.props.displayVehicles);

      setTimeout(() => {
        this.fetchData();
      }, 100);
    }
  }

  attachGeofenceData = (vehicleItem, lat, lng) => {
    const fenceEx =
      vehicleItem.geofenceStand ||
      getFencePlan(this.state.geofencePoints) ||
      [];

    try {
      for (let fenceIndex = 0; fenceIndex < fenceEx.length; fenceIndex++) {
        fenceEx[fenceIndex].liveStatus = isPolyInside(
          [parseFloat(lat), parseFloat(lng)],
          fenceEx[fenceIndex].boundariesPoints
        );
      }
    } catch (error) {
      console.log("fence Error 309", error);
    }

    return fenceEx;
  };

  toggleGDDPlaySpeed = () => {
    this.setState((prevState) => ({
      isOpenGDDPlaySpeed: !prevState.isOpenGDDPlaySpeed,
    }));
  };

  progressCalc = () => {
    const displayVehicles = this.state.displayVehicles;

    let countOfProgressing = 0;
    let countOfPending = 0;
    let countOfDone = 0;
    let countOfFailed = 0;
    for (const v of displayVehicles) {
      countOfProgressing += v.historyCallStatus === "progressing" ? 1 : 0;
      countOfPending += v.historyCallStatus === "pending" ? 1 : 0;
      countOfDone += v.historyCallStatus === "done" ? 1 : 0;
      countOfFailed += v.historyCallStatus === "failed" ? 1 : 0;
    }

    const countOfTotal =
      countOfProgressing + countOfPending + countOfDone + countOfFailed;
    const progressingPercentage =
      ((countOfDone + countOfFailed) / countOfTotal) * 100;

    if (progressingPercentage === 100) {
      this.setState({ loadingModalIsOpen: false });
    }

    this.setState({ fetchDataProgress: progressingPercentage });
  };

  fetchData = () => {
    const displayVehicles = this.state.displayVehicles;
    const startTime = AppHelperDateToDBString(this.state.startTime.toDate());
    const endTime = AppHelperDateToDBString(this.state.endTime.toDate());

    let historyCallCounter = 0;
    let pendingCounter = 0;
    let progressingCounter = 0;

    this.setState({ loadingModalIsOpen: true });

    for (let index = 0; index < displayVehicles.length; index++) {
      const v = displayVehicles[index];

      if (v.historyCallStatus === "progressing") {
        progressingCounter += 1;
        if (!(progressingCounter % 5)) {
          break;
        }
      } else if (v.historyCallStatus === "pending") {
        pendingCounter += 1;
        if (!(pendingCounter % 5)) {
          break;
        }

        v.historyCallStatus = "progressing";
        displayVehicles[index] = v;

        v.historyCall
          .then((response) => {
            console.log("the responce ", response);
            let wayPoints = [];
            const stickers = [];
            response.forEach((el) => {
              if (el.message !== "success") {
                return;
              }

              wayPoints = wayPoints.concat(el.pathWay || []);

              el.stops.forEach((elItem) => {
                const time = moment(elItem.startTime);
                stickers.push({
                  ...geo2LatLng(elItem.geo),
                  title: "Stop",
                  timeText: time.format("DD, hh:mm A"),
                  time: new Date(elItem.startTime),
                  details: "",
                  type: "blue",
                });
              });

              el.idles.forEach((elItem) => {
                const startTime = moment(elItem.startTime);
                const endTime = moment(elItem.endTime);

                stickers.push({
                  ...geo2LatLng(elItem.geo),
                  title: "Idle",
                  timeText: startTime.format("DD, hh:mm A"),
                  time: new Date(elItem.startTime),
                  details:
                    " duration: " +
                    AppHelperDurationAsHHmm(
                      startTime.toDate(),
                      endTime.toDate()
                    ),
                  type: "red",
                });
              });

              el.overSpeeds.forEach((elItem) => {
                const time = moment(elItem.eventTime);
                let details =
                  "Seconds: " +
                  (elItem.totalSeconds > 15 ? "15+" : elItem.totalSeconds);
                details = details + ", Limit: " + elItem.speedLimit;
                details = details + ", Speed: " + elItem.topSpeed;

                stickers.push({
                  ...geo2LatLng(elItem.geo),
                  title: "Over speed",
                  timeText: time.format("DD, hh:mm A"),
                  time: new Date(elItem.eventTime),
                  details,
                  type: "yellow",
                });
              });
            });

            const wayPointsTrimmed = wayPoints.filter(
              (x) => x.updatetime >= startTime && x.updatetime <= endTime
            );

            const wayPointB = wayPointsTrimmed.map((w) => {
              const time = moment(w.updatetime);
              return {
                ...w,
                lat: parseFloat(w.lat),
                lng: parseFloat(w.log),
                time: new Date(w.updatetime),
                timeText: time.format("DD, hh:mm:ss A"),
              };
            });

            // this.setState({ wayPoints: wayPointsTrimmed, stickers, loading: false });

            v.historyCallResponse = {
              wayPoints: wayPointB,
              stickers,
              loading: false,
            };

            v.historyCallStatus = "done";
            v.showMapBox = false;
            v.vehicleState = "off";

            const maps = this.state.maps || {};
            const map = this.state.map || {};
            v.mapMarker = new maps.Marker({
              id: `vehicle_${v.item.key}`,
              icon: VEHICLE_ICON_OFF,
              map: map,
              title: `${v.item.plate_number} ${v.item.fleet_number}`,
            });

            maps.event.addListener(v.mapMarker, "click", (e) => {
              this.handleVehicleMapMarkerClick(v);
            });

            if (wayPointB.length) {
              const firstOne = wayPointB[0];
              const coordinate = {
                lat: firstOne.lat,
                lng: firstOne.lng,
                distance: "0.00",
                km: 0,
                speed: firstOne.speed,
                updatetime: firstOne.updatetime,
                fuel: firstOne.fuel,
                timeText: firstOne.timeText,
              };
              v.coordinate = coordinate;
              v.mapMarker.setPosition(coordinate);
            }

            const vehicleList = this.state.displayVehicles;
            vehicleList[index] = v;
            this.setState({ displayVehicles: vehicleList });

            setTimeout(() => {
              this.progressCalc();
            }, 100);
          })
          .catch((error) => {
            v.historyCallStatus = "failed";
            displayVehicles[index] = v;

            const vehicleList = this.state.displayVehicles;
            vehicleList[index] = v;
            this.setState({ displayVehicles: vehicleList });

            console.error(error);
            setTimeout(() => {
              this.progressCalc();
            }, 100);
          });
      } else {
        historyCallCounter += 1;
      }

      /*
      if (v.historyCallStatus === "pending") {
        v.historyCallStatus = "progressing";
        displayVehicles[index] = v;
        this.setState({ displayVehicles });
        console.log('calling for ', v.key, v.plate_number);
        
      } else {
        historyCallCounter += 1;
      }*/

      // console.log('counter check', {c:historyCallCounter, l:displayVehicles.length });
    }

    this.setState({ displayVehicles });

    setTimeout(() => {
      this.progressCalc();
    }, 100);

    if (historyCallCounter !== displayVehicles.length) {
      setTimeout(() => {
        this.fetchData();
      }, 1000 * 2);
    }
  };

  onMapLoaded = (map, maps) => {
    this.setState({
      mapsLoaded: true,
      map: map,
      maps: maps,
    });

    this.mapDrawing = MapDrawing.init(
      map,
      maps,
      this.OnMapDrawingCompleted,
      this.OnMapDrawingAddNew,
      this.OnMapDrawingAddNew_FavoritePlace
    );

    /*
    setTimeout(() => {
      this.onAddNewPOI();
    }, 10);
    */

    this.generateGeofencePoints();
    this.generateFavoritePlaces();
  };

  OnMapDrawingCompleted = (pathPoints) => {
    // console.log("log ", this.props.geofence);
  };

  OnMapDrawingAddNew = (name, speed, coordinates) => {
    const points = coordinates.join("");
    const user = this.props.user;

    const userDetails = this.props.userDetails;

    if(userDetails.roleText === "User" ){

      NotificationManager.error(
        'Geofence can add only by Owner or Admin',
        "Permission denied",
        5000,
        null,
        null,
        ""
      );
      return;
    }

    GeofenceAdd(user.userToken, name, points, speed).then((response) => {
      if (response) {
        this.setState({ loading: false });
        if (response) {
          if (response.status === "success") {
            this.props.geofenceSelectRequest(this.props.history);

            NotificationManager.success(
              response.message,
              "Success",
              3000,
              null,
              null,
              ""
            );
          } else {
            NotificationManager.warning(
              response.message,
              response.status,
              5000,
              null,
              null,
              ""
            );
          }
        }
      }
    });
  };

  OnMapDrawingAddNew_FavoritePlace = (title, geo, radius, typeText, type) => {
    // const points = geo;
    const user = this.props.user;
    FavoritePlaceAdd(user.userToken, title, geo, radius, typeText, type).then(
      (response) => {
        if (response) {
          this.setState({ loading: false });

          if (response) {
            if (response.status === "success") {
              this.props.favoritePlacesSelectRequest(this.props.history);

              NotificationManager.success(
                response.message,
                "Success",
                3000,
                null,
                null,
                ""
              );
            } else {
              NotificationManager.warning(
                response.message,
                response.status,
                5000,
                null,
                null,
                ""
              );
            }
          }
        }
      }
    );
  };

  generateGeofencePoints = () => {
    const userDetails = this.props.userDetails || {};
    const userRole = userDetails.role || 3;

    // if user no geofence 
    if (userRole === 3) {
      return ; 
    }
    
    if (!this.state.map || !this.state.maps || !this.props.geofenceAll) {
      this.setState({ geofencePoints: [] });
      return;
    }

    const geofenceAll = this.props.geofenceAll || [];
    MapPolygon.updatePolygons(
      geofenceAll,
      this.state.map,
      this.state.maps,
      this.onGeofenceUpdate,
      this.onGeofenceDelete
    );

    // TODO: R1: Load geofence here
    this.setState({
      geofencePoints: geofenceAll.map((x) => {
        return {
          ...x,
          vehicles: [],
        };
      }),
    });
  };

  onGeofenceUpdate = (id, title, speed) => {
    const user = this.props.user;

    const userDetails = this.props.userDetails;

    if(userDetails.roleText === "User" ){

      NotificationManager.error(
        'Geofence can update only by Owner or Admin',
        "Permission denied",
        5000,
        null,
        null,
        ""
      );
      return;
    }
    
    GeofenceUpdate(user.userToken, id, title, speed).then((response) => {
      if (response) {
        this.setState({ loading: false });
        if (response) {
          if (response.status === "success") {
            this.props.geofenceSelectRequest(this.props.history);
            NotificationManager.success(
              response.message,
              "Success",
              3000,
              null,
              null,
              ""
            );
          } else {
            NotificationManager.warning(
              response.message,
              response.status,
              5000,
              null,
              null,
              ""
            );
          }
        }
      }
    });
  };

  onGeofenceDelete = (id) => {
    const user = this.props.user;

    const userDetails = this.props.userDetails;

    if(userDetails.roleText === "User" ){

      NotificationManager.error(
        'Geofence can delete only by Owner or Admin',
        "Permission denied",
        5000,
        null,
        null,
        ""
      );
      return;
    }

    GeofenceDelete(user.userToken, id).then((response) => {
      if (response) {
        this.setState({ loading: false });
        if (response) {
          if (response.status === "success") {
            this.props.geofenceSelectRequest(this.props.history);
            NotificationManager.success(
              response.message,
              "Success",
              3000,
              null,
              null,
              ""
            );
          } else {
            NotificationManager.warning(
              response.message,
              response.status,
              5000,
              null,
              null,
              ""
            );
          }
        }
      }
    });
  };

  generateFavoritePlaces = () => {
    if (!this.state.map || !this.state.maps || !this.props.favoritePlacesAll) {
      this.setState({ favoritePlacePoints: [] });
      return;
    }

    const favoritePlacesAll = this.props.favoritePlacesAll || [];

    MapFavoritePlaces.updateMarkers(
      favoritePlacesAll,
      this.state.map,
      this.state.maps,
      this.onFavoritePlaceDelete
    );

    this.setState({ favoritePlacePoints: favoritePlacesAll });
  };

  onFavoritePlaceDelete = (id) => {
    const user = this.props.user;
    FavoritePlacesDelete(user.userToken, id).then((response) => {
      if (response) {
        this.setState({ loading: false });
        if (response) {
          if (response.status === "success") {
            this.props.favoritePlacesSelectRequest(this.props.history);
            NotificationManager.success(
              response.message,
              "Success",
              3000,
              null,
              null,
              ""
            );
          } else {
            NotificationManager.warning(
              response.message,
              response.status,
              5000,
              null,
              null,
              ""
            );
          }
        }
      }
    });
  };

  onGeofenceSelect = (item) => {
    MapPolygon.toggle(item.autoid);
  };

  onFavoritePlacesSelectAll = (visibility) => {
    MapFavoritePlaces.showAll(visibility);
  };

  onFavoritePlacesSelect = (item) => {
    MapFavoritePlaces.toggle(item.autoid, true);
  };

  handleSeekBarChange = (newValue) => {
    if (newValue > this.state.seekBarMax) {
      this.setState({ seekBarValue: this.state.seekBarMax });
    } else if (newValue < this.state.seekBarMin) {
      this.setState({ seekBarValue: this.state.seekBarMin });
    } else {
      this.setState({ seekBarValue: newValue });
    }

    this.findVehiclePosition(newValue);

    /*
    if (this.seekBarInterval) {
      clearTimeout(this.seekBarInterval);
      this.seekBarInterval = null;
    }

    this.seekBarInterval = setTimeout(() => {
      this.findVehiclePosition(newValue);
    }, 100);
    */
  };

  findVehiclePosition = (barValue) => {
    const seekBarValue = barValue || this.state.seekBarValue;
    const displayVehicles = this.state.displayVehicles || [];

    const seekTime = this.state.startTime
      .clone()
      .add(seekBarValue, "seconds")
      .toDate();

    let running = 0;
    let idle = 0;
    let off = 0;

    for (let index = 0; index < displayVehicles.length; index++) {
      const v = displayVehicles[index];

      if (v.historyCallStatus === "done" && v.coordinate) {
        const historyResponse = v.historyCallResponse;
        const wayPoints = historyResponse.wayPoints;

        let positionPoint = wayPoints[0];
        let indexC = 0;
        for (indexC = 0; indexC < wayPoints.length; indexC++) {
          const c = wayPoints[indexC];

          if (c.time <= seekTime) {
            positionPoint = c;
          }
        }

        if (v.coordinate !== positionPoint) {
          const coordinate = {
            lat: positionPoint.lat,
            lng: positionPoint.lng,
            distance: "0.00",
            km: 0,
            speed: positionPoint.speed,
            updatetime: positionPoint.updatetime,
            fuel: positionPoint.fuel,
            timeText: positionPoint.timeText,
          };

          v.geofenceSelectedFenceIn = false;
          v.geofenceStandSelected = null;
          v.geofenceStand = this.attachGeofenceData(
            v,
            positionPoint.lat,
            positionPoint.lng
          );
          const selectedFence = this.props.selectedGeofence || {};
          v.geofenceStand.forEach((fenceEx) => {
            if (selectedFence.autoid === fenceEx.autoid) {
              v.geofenceStandSelected = fenceEx;
              v.geofenceSelectedFenceIn = fenceEx.liveStatus;
            }
          });

          v.mapMarker.setVisible(v.geofenceSelectedFenceIn);

          v.coordinate = coordinate;
          v.mapMarker.setPosition(coordinate);
          v.mapMarker.setIcon(VEHICLE_ICON_RUNNING);
          v.vehicleState = "running";

          if (positionPoint.speed === 0) {
            v.mapMarker.setIcon(VEHICLE_ICON_IDLE);
            v.vehicleState = "idle";
          }

          // off if between two records over 10 min
          if (indexC && wayPoints[indexC - 1]) {
            if (
              positionPoint.time - wayPoints[indexC - 1].time >
              MILLISECONDS_10MIN
            ) {
              v.mapMarker.setIcon(VEHICLE_ICON_OFF);
              v.vehicleState = "off";
            }
          }

          // off if it is last record
          if (positionPoint === wayPoints[wayPoints.length - 1]) {
            v.mapMarker.setIcon(VEHICLE_ICON_OFF);
            v.vehicleState = "off";
          }

          // off when 10 min over
          if (seekTime - positionPoint.time > MILLISECONDS_10MIN) {
            v.mapMarker.setIcon(VEHICLE_ICON_OFF);
            v.vehicleState = "off";
          }
        }

        switch (v.vehicleState) {
          case "off":
            off += 1;
            break;

          case "running":
            running += 1;
            break;
          case "idle":
            idle += 1;
            break;

          default:
            break;
        }

        displayVehicles[index] = v;
      }
    }

    this.setState({ displayVehicles, idle, running, off });
  };

  searchHandler = (event, displayVehicles) => {
    let searchValue = event
      ? event.target.value.toLowerCase()
      : this.state.searchText;

    if (!searchValue && displayVehicles) {
      this.setState({
        filteredVehicles: displayVehicles || [],
        searchText: searchValue,
      });
      return;
    }

    let arrData = searchValue.split(" ");
    let tempDataRows = this.state.displayVehicles;
    let filteredDataRows = this.state.displayVehicles;
    arrData.forEach((element) => {
      filteredDataRows = tempDataRows.filter((el) => {
        return (
          el.item.plate_number.toLowerCase().indexOf(element) !== -1 ||
          el.item.fleet_number.toLowerCase().indexOf(element) !== -1
        );
      });

      tempDataRows = filteredDataRows;
    });

    this.setState({
      filteredVehicles: filteredDataRows || [],
      searchText: searchValue,
    });
  };

  getVehicleListClassName = (status) => {
    switch (status) {
      case "pending":
        return "bg-warning";
      case "progressing":
        return "bg-primary";
      case "failed":
        return "bg-danger";
      default:
        return "";
    }
  };

  playNextFrame = () => {
    if (this.state.seekBarValue === this.state.seekBarMax) {
      clearInterval(this.playInterval);
      this.setState({ playBtnText: "Play" });

      return;
    }

    this.handleSeekBarChange(
      this.state.seekBarValue + this.state.playSpeedValue
    );
  };

  handleOnPlay = () => {
    if (this.state.playBtnText === "Pause") {
      if (this.playInterval) {
        clearInterval(this.playInterval);
        this.playInterval = null;
      }

      this.setState({ playBtnText: "Play" });
      return;
    }

    if (this.playInterval) {
      clearInterval(this.playInterval);
      this.playInterval = null;
    } else {
      if (this.state.seekBarValue === this.state.seekBarMax) {
        this.setState({ seekBarValue: 0 });
      }
      // this.state.map.setCenter(this.state.coordinates[this.state.seekBarValue]);
      // this.state.map.setZoom(14);
      this.setState({ playBtnText: "Pause" });
      this.playInterval = setInterval(this.playNextFrame, 1000);
    }
  };

  handlePlaySpeedChange = (item) => {
    this.setState({
      playSpeedValue: item.value,
      selectedPageText: item.label,
    });
  };

  handleVehicleItemClick = (item, noZooom) => {
    if (!item.coordinate) {
      return;
    }
    const displayVehicles = this.state.displayVehicles;
    for (let index = 0; index < displayVehicles.length; index++) {
      const v = displayVehicles[index];
      if (v.item.key === item.item.key && v.coordinate) {
        v.showMapBox = !v.showMapBox;

        const map = this.state.map || {};

        if (!noZooom) {
          map.setZoom(16);
          map.setCenter(v.coordinate);
        }

        displayVehicles[index] = v;
        break;
      }
    }

    this.setState({ displayVehicles });
  };

  handleVehicleMapMarkerClick = (vehicleItem) => {
    const wayLine = this.state.wayLine;

    if (wayLine) {
      wayLine.setPath([]);
      wayLine.setMap(null);

      this.setState({ wayLine: null });
      return;
    }

    this.handleVehicleItemClick(vehicleItem, true);

    const maps = this.state.maps || {};
    const map = this.state.map || {};
    const coordinates = vehicleItem.historyCallResponse.wayPoints;
    const mapLine = new maps.Polyline({
      id: vehicleItem.item.key,
      path: coordinates,
      geodesic: true,
      strokeColor: "black",
      strokeOpacity: 0.5,
      strokeWeight: 5,
      icons: [
        {
          icon: {
            path: window.google.maps.SymbolPath.CIRCLE,
            scale: 8,
            strokeColor: "green",
            title: "Start",
          },
          offset: "100%",
        },
        {
          icon: {
            path: window.google.maps.SymbolPath.CIRCLE,
            scale: 8,
            strokeColor: "orange",
            title: "end",
          },
          offset: "0%",
        },
      ],
      map: map,
    });

    this.setState({ wayLine: mapLine });
  };

  render() {
    const favoritePlacesAll = this.state.favoritePlacePoints || [];
    const geofenceAll = this.state.geofencePoints || [];
    const filteredVehicles = this.state.filteredVehicles || [];
    const displayVehicles = this.state.displayVehicles || [];

    return (
      <React.Fragment>
        <Modal isOpen={this.state.loadingModalIsOpen} size="lg">
          <ModalBody>
            <Row>
              <Colxx xxs="12">
                <h3> Loading {Math.round(this.state.fetchDataProgress)}%</h3>
                <Progress
                  value={this.state.fetchDataProgress}
                  className="mb-2"
                />

                {filteredVehicles.map((v, i) => (
                  <Badge
                    color={v.historyCallStatus === "done" ? "light" : "warning"}
                    key={v.item.key}
                    className={"mb-1 mr-1"}
                  >
                    <span>
                      {v.item.plate_number} {v.item.fleet_number}{" "}
                      {v.historyCallStatus === "done"
                        ? null
                        : v.historyCallStatus}
                    </span>
                  </Badge>
                ))}
              </Colxx>
            </Row>
          </ModalBody>
        </Modal>

        <Row>
          {this.state.fetchDataProgress === 100 ? null : (
            <Colxx xxs="12">
              <Progress value={this.state.fetchDataProgress} />
            </Colxx>
          )}
        </Row>
        <Row className="live-tile m-0">
          <Colxx xl="3" lg="6" md="12" className="bg-primary pl-2">
            <IntlMessages id="common.total" /> {displayVehicles.length}
          </Colxx>
          <Colxx xl="3" lg="6" md="12" className="bg-success pl-2">
            <IntlMessages id="common.running" /> {this.state.running}
          </Colxx>
          <Colxx xl="3" lg="6" md="12" className="bg-danger pl-2">
            <IntlMessages id="common.idle" /> {this.state.idle}
          </Colxx>
          <Colxx xl="3" lg="6" md="12" className="bg-dark2 pl-2">
            <IntlMessages id="common.off" /> {this.state.off}
          </Colxx>
        </Row>

        <Row className="m-0">
          <Colxx xl="2" lg="2" md="12" className="p-0">
            <div className="dashboard-map overflow-h">
              <input
                type="text"
                className="form-control"
                placeholder={"Search vehicle"}
                value={this.state.searchText}
                onChange={this.searchHandler}
              />
            </div>

            <div
              className="dashboard-map scroll2 w-100 "
              style={{ maxHeight: "505px" }}
            >
              <Table hover bordered>
                <tbody>
                  {filteredVehicles.map((v, i) => (
                    <tr
                      key={v.item.key}
                      className={this.getVehicleListClassName(
                        v.historyCallStatus
                      )}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        this.handleVehicleItemClick(v);
                      }}
                    >
                      <td>
                        {v.item.plate_number} {v.item.fleet_number}{" "}
                        <Badge
                          color={v.geofenceSelectedFenceIn ? "dark" : "light"}
                        >
                          {v.geofenceSelectedFenceIn ? "In" : "Out"}
                        </Badge>
                        {v.historyCallStatus === "done" ? (
                          <small className="d-inline-block" key="vehicleState">
                            {v.vehicleState}
                          </small>
                        ) : (
                          <small className="d-block" key="historyCallStatus">
                            {v.historyCallStatus}{" "}
                          </small>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Colxx>
          <Colxx xl="10" lg="10" md="12" className="p-0">
            <div style={{ height: MAP_HEIGHT }} className="w-100">
              <GoogleMap
                yesIWantToUseGoogleMapApiInternals={true}
                bootstrapURLKeys={{
                  key: "AIzaSyAKjw_wuep7sJmjM6tKylxLr6Yo_bHUxnw",
                  libraries: ["drawing"].join(","),
                }}
                defaultCenter={[25.273217, 51.414257]}
                defaultZoom={8}
                options={{ mapTypeControl: true }}
                onGoogleApiLoaded={({ map, maps }) => {
                  this.onMapLoaded(map, maps);
                }}
              >
                {displayVehicles.map((v) => {
                  if (!v.coordinate || !v.showMapBox) {
                    return null;
                  }

                  return (
                    <div
                      key={v.item.key}
                      lat={v.coordinate.lat}
                      lng={v.coordinate.lng}
                      className={`d-inline-block tile-${v.vehicleState} text-light p-1 border`}
                    >
                      {v.item.plate_number} {v.item.fleet_number} <br />
                      <img
                        title={v.item.typeText}
                        className="app-select-icon d-inline-block"
                        src={v.item.typeIcon}
                        alt="icon"
                      />
                    </div>
                  );
                })}
              </GoogleMap>
            </div>
          </Colxx>
          <Colxx xxs="12" sm="12">
            <SliderTooltip
              min={0}
              max={this.state.seekBarMax}
              value={this.state.seekBarValue}
              defaultValue={0}
              className="border p-2"
              onChange={this.handleSeekBarChange}
              handle={sliderHandle}
            />
          </Colxx>

          <Colxx xxs="12" sm="12">
            <h3 className="d-inline">
              {this.state.startTime
                .clone()
                .add(this.state.seekBarValue, "seconds")
                .format("MMMDD hh:mm:ss A")}{" "}
            </h3>

            <ButtonGroup className="flex-wrap p-1">
              <Button
                outline
                color="primary"
                size="xs"
                onClick={this.handleOnPlay}
              >
                {this.state.playBtnText === "Play" ? (
                  <i className="simple-icon-control-play"></i>
                ) : (
                  <i className="simple-icon-control-pause"></i>
                )}{" "}
                {this.state.playBtnText}
              </Button>

              <ButtonDropdown
                className="bd-container"
                isOpen={this.state.isOpenGDDPlaySpeed}
                toggle={this.toggleGDDPlaySpeed}
                key="by-page-btn"
              >
                <DropdownToggle caret size="xs" outline color="primary">
                  {this.state.selectedPageText}
                </DropdownToggle>
                <DropdownMenu
                  modifiers={{
                    setMaxHeight: {
                      enabled: true,
                      order: 890,
                      fn: (data) => {
                        return {
                          ...data,
                          styles: {
                            ...data.styles,
                            overflow: "auto",
                            maxHeight: 221,
                          },
                        };
                      },
                    },
                  }}
                >
                  {playSpeedOptions.map((el, index) => (
                    <DropdownItem
                      key={index}
                      value={el}
                      onClick={() => this.handlePlaySpeedChange(el)}
                    >
                      {`${el.label}`}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </ButtonDropdown>

              <Button
                outline
                color="info"
                size="xs"
                onClick={() => {
                  this.handleSeekBarChange(this.state.seekBarMin);
                }}
              >
                <i className="simple-icon-control-start"></i>{" "}
                <IntlMessages id="tracking.reset" />
              </Button>
              <Button
                outline
                color="info"
                size="xs"
                onClick={() => {
                  // this.handleOnPlaySpeedChange("slow");

                  if (this.state.seekBarValue > this.state.seekBarMin) {
                    this.handleSeekBarChange(
                      this.state.seekBarValue - this.state.playSpeedValue
                    );
                  }
                }}
              >
                <i className="simple-icon-control-rewind"></i> Seek
              </Button>

              <Button
                outline
                color="info"
                size="xs"
                onClick={() => {
                  // this.handleOnPlaySpeedChange("fast");
                  if (this.state.seekBarMax > this.state.seekBarValue) {
                    this.handleSeekBarChange(
                      this.state.seekBarValue + this.state.playSpeedValue
                    );
                  }
                }}
              >
                <i className="simple-icon-control-forward"></i> Seek
              </Button>
            </ButtonGroup>
          </Colxx>
        </Row>
        <Row className="m-0">
          <Colxx xl="6" lg="6" md="6" sm="12">
            <div className="mt-2 mb-2">
              <strong className="text-muted">
                <IntlMessages id="common.geofence" />
              </strong>
              <br />
              {geofenceAll.map((item, index) => {
                return (
                  <Button
                    outline
                    color="primary"
                    size="xs"
                    key={index}
                    className="mr-1 mb-1"
                    onClick={() => this.onGeofenceSelect(item)}
                  >
                    {item.title}
                  </Button>
                );
              })}
              {(favoritePlacesAll.length ? {} : null) && (
                <div className="mt-2 mb-2">
                  <strong className="text-muted">Favorite Places</strong>
                  <Button
                    className="ml-1"
                    size="xs"
                    onClick={() => {
                      this.onFavoritePlacesSelectAll(
                        this.state.favoritePlacesVisibility
                      );
                      this.setState({
                        favoritePlacesVisibility:
                          !this.state.favoritePlacesVisibility,
                      });
                    }}
                  >
                    {this.state.favoritePlacesVisibility ? (
                      <IntlMessages id="tracking.show-all" />
                    ) : (
                      <IntlMessages id="tracking.hide-all" />
                    )}
                  </Button>
                  <br />
                  {favoritePlacesAll.map((item, index) => {
                    return (
                      <Button
                        outline
                        color="primary"
                        size="xs"
                        key={index}
                        className="mr-1 mt-1"
                        onClick={() => this.onFavoritePlacesSelect(item)}
                      >
                        {item.title}
                      </Button>
                    );
                  })}
                </div>
              )}
            </div>
          </Colxx>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  vehicles,
  authUser,
  settings,
  geofence,
  favoritePlaces,
}) => {
  const { all } = vehicles;
  const { user, details } = authUser;
  const { locale } = settings;
  const { items } = geofence;
  const favoritePlacesAll = favoritePlaces.items || [];
  return {
    vehiclesAll: all,
    user,
    locale,
    geofenceAll: items,
    favoritePlacesAll,
    userDetails: details,
  };
};

export default connect(mapStateToProps, {
  authApiAccessCheck,
  geofenceSelectRequest,
  favoritePlacesSelectRequest,
})(GeofenceHistoryMap);
