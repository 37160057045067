import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Card, CardBody, Row, Table } from "reactstrap";
import "rc-slider/assets/index.css";
import GoogleMap from "google-map-react";
import { Chart } from "react-google-charts";

import { Colxx } from "./common/CustomBootstrap";
import { geofenceSelectRequest } from "../redux/actions";
import VehicleCard from "./VehicleCard";

import MapDrawing from "../map-helper/map-drawing";
import MapPolygon from "../map-helper/map-polygon";
import {
  GeofenceAdd,
  GeofenceUpdate,
  GeofenceDelete
} from "../api-tasks/geofence-changes";
import { NotificationManager } from "./common/react-notifications";
import {
  AppHelper_DDhhmmT,
  AppHelperDateAndTimeToString,
  AppHelperSecondsAsHHmm
} from "../helpers/AppHelper";

import apiTaskAppPath from "../api-tasks/app-path";

/*
const FORWARD_CLOSED_ARROW = {
  icon: {
    path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
    scale: 5,
    strokeColor: "green",
    title: "Start"
  },
  offset: "100%"
};

const SYMBOL_PATH_CIRCLE = {
  icon: {
    path: window.google.maps.SymbolPath.CIRCLE,
    scale: 8,
    strokeColor: "orange",
    title: "end"
  },
  offset: "0%"
}*/


function distance(lat1, lon1, lat2, lon2, unit) {
  if (lat1 === 0 || lat2 === 0 || lon1 === 0 || lon2 === 0) {
    return 0;
  }

  if (lat1 === lat2 && lon1 === lon2) {
    return 0;
  } else {
    var radlat1 = (Math.PI * lat1) / 180;
    var radlat2 = (Math.PI * lat2) / 180;
    var theta = lon1 - lon2;
    var radtheta = (Math.PI * theta) / 180;
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit === "K") {
      dist = dist * 1.609344;
    }
    if (unit === "N") {
      dist = dist * 0.8684;
    }
    return dist;
  }
}

const speedChartDataEmpty = [
  ["Time", "Speed", "National Limit"],
  [AppHelper_DDhhmmT(new Date()), 0, 120]
];

class HistoryPlaybackPrinter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedVehicle: undefined,
      mapsLoaded: false,
      map: null,
      maps: null,
      locationControlVisible: false,
      wayPoints: [],
      coordinates: [],
      geofencePoints: [],
      seekBarMin: 0,
      seekBarMax: 0,
      seekBarValue: 0,
      topSpeed: {},
      playSpeed: 200,
      mapPolyLines: [],
      speedChartData: speedChartDataEmpty
    };

    this.intervalId = null;
  }

  componentWillUnmount() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  componentDidMount() {
    if (this.props.wayPoints && this.props.wayPoints.length) {
      this.onWayPointsChange(this.props.wayPoints);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.wayPoints !== this.props.wayPoints) {
      this.onWayPointsChange(this.props.wayPoints);

      // if line was there
      if (!this.props.wayPoints.length && this.state.wayLine) {
        this.state.wayLine.setPath([]);
      }
    }

    if (prevProps.geofenceAll !== this.props.geofenceAll) {
      this.generateGeofencePoints();
    }
  }

  onGeofenceDelete = id => {
    const user = this.props.user;

    const userDetails = this.props.userDetails;

    if(userDetails.roleText === "User" ){

      NotificationManager.error(
        'Geofence can delete only by Owner or Admin',
        "Permission denied",
        5000,
        null,
        null,
        ""
      );
      return;
    }

    GeofenceDelete(user.userToken, id).then(response => {
      if (response) {
        this.setState({ loading: false });
        if (response) {
          if (response.status === "success") {
            this.props.geofenceSelectRequest(this.props.history);
            NotificationManager.success(
              response.message,
              "Success",
              3000,
              null,
              null,
              ""
            );
          } else {
            console.error("geofence.add", response);
            NotificationManager.warning(
              response.message,
              response.status,
              5000,
              null,
              null,
              ""
            );
          }
        }
      }
    });
  };

  onGeofenceUpdate = (id, title, speed) => {
    const user = this.props.user;

    const userDetails = this.props.userDetails;

    if(userDetails.roleText === "User" ){

      NotificationManager.error(
        'Geofence can update only by Owner or Admin',
        "Permission denied",
        5000,
        null,
        null,
        ""
      );
      return;
    }
    
    GeofenceUpdate(user.userToken, id, title, speed).then(response => {
      if (response) {
        this.setState({ loading: false });
        if (response) {
          if (response.status === "success") {
            this.props.geofenceSelectRequest(this.props.history);
            NotificationManager.success(
              response.message,
              "Success",
              3000,
              null,
              null,
              ""
            );
          } else {
            console.error("geofence.add", response);
            NotificationManager.warning(
              response.message,
              response.status,
              5000,
              null,
              null,
              ""
            );
          }
        }
      }
    });
  };

  generateGeofencePoints = () => {
    
    const userDetails = this.props.userDetails || {};
    const userRole = userDetails.role || 3;

    // if user no geofence 
    if (userRole === 3) {
      return ; 
    }
    
    if (!this.state.map || !this.state.maps || !this.props.geofenceAll) {
      this.setState({ geofencePoints: [] });
      return;
    }

    const geofenceAll = this.props.geofenceAll || [];
    MapPolygon.updatePolygons(
      geofenceAll,
      this.state.map,
      this.state.maps,
      this.onGeofenceUpdate,
      this.onGeofenceDelete
    );

    this.setState({ geofencePoints: geofenceAll });
  };

  onMapLoaded(map, maps) {
    this.setState({
      mapsLoaded: true,
      map: map,
      maps: maps
    });

    if (this.props.wayPoints && this.props.wayPoints.length) {
      this.onWayPointsChange(this.props.wayPoints);
    }

    this.mapDrawing = MapDrawing.init(
      map,
      maps,
      this.OnMapDrawingCompleted,
      this.OnMapDrawingAddNew
    );
  }

  OnMapDrawingCompleted = pathPoints => {
    console.log("log ", this.props.geofence);
  };

  OnMapDrawingAddNew = (name, speed, coordinates) => {
    const points = coordinates.join("");
    const user = this.props.user;

    const userDetails = this.props.userDetails;

    if(userDetails.roleText === "User" ){

      NotificationManager.error(
        'Geofence can add only by Owner or Admin',
        "Permission denied",
        5000,
        null,
        null,
        ""
      );
      return;
    }

    GeofenceAdd(user.userToken, name, points, speed).then(response => {
      if (response) {
        this.setState({ loading: false });
        if (response) {
          if (response.status === "success") {
            this.props.geofenceSelectRequest(this.props.history);

            NotificationManager.success(
              response.message,
              "Success",
              3000,
              null,
              null,
              ""
            );
          } else {
            console.error("geofence.add", response);
            NotificationManager.warning(
              response.message,
              response.status,
              5000,
              null,
              null,
              ""
            );
          }
        }
      }
    });
  };

  onWayPointsChange = wayPoints => {
    this.setState({ wayPoints });
    this.setState({
      wayPoints,
      seekBarMax: wayPoints.length ? wayPoints.length - 1 : 0,
      seekBarValue: 0,
      speedChartData: speedChartDataEmpty
    });

    if (wayPoints && !wayPoints.length) return;

    let km = 0;
    let lineId = 0;
    const coordinates =
      wayPoints.map((x, index) => {
        const prev = wayPoints[index - 1] || {};
        const kmDiff = distance(prev.lat, prev.log, x.lat, x.log, "K");

        lineId = km + kmDiff - km > 11 ? lineId + 1 : lineId;
        km = km + (kmDiff || 0);

        return {
          lat: parseFloat(x.lat),
          lng: parseFloat(x.log),
          distance: km.toFixed(2),
          km,
          lineId
        };
      }) || [];

    const speedChartData = wayPoints.map(x => {
      return [AppHelper_DDhhmmT(x.updatetime), x.speed, 120];
    });

    speedChartData.splice(0, 0, speedChartDataEmpty[0]);

    this.updateMapPolyLines(coordinates, lineId);

    const topSpeed = wayPoints.length
      ? wayPoints.reduce(function(prev, current) {
          return prev.speed > current.speed ? prev : current;
        })
      : {};

    this.setState({
      seekBarMax: wayPoints.length - 1,
      coordinates,
      seekBarValue: 0,
      topSpeed,
      speedChartData
    });

    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  };

  updateMapPolyLines(coordinates, lines) {
    if (!this.state.mapsLoaded) {
      return;
    }

    const maps = this.state.maps || {};
    const map = this.state.map || {};
    const mapPolyLines = this.state.mapPolyLines || [];
    for (let index = 0; index < mapPolyLines.length; index++) {
      if (mapPolyLines[index]) {
        mapPolyLines[index].setPath([]);
      }
    }

    for (let index = 0; index <= lines; index++) {
      const points = coordinates.filter(el => el.lineId === index);

      if (mapPolyLines[index]) {
        mapPolyLines[index].setPath(points);
      } else {
        const aItem = new maps.Polyline({
          path: points,
          geodesic: true,
          strokeColor: "black",
          strokeOpacity: 0.6,
          strokeWeight: 5,
          map: map
        });

        mapPolyLines.push(aItem);
      }

      map.setCenter(coordinates[0]);

      const mapMarker =
        this.state.mapMarker ||
        new maps.Marker({
          id: "h1",
          icon: "/map-assets/pickup_running.png",
          map: map
        });

      this.setState({ mapPolyLines, mapMarker });
    }
  }

  updateWayLine(coordinates) {
    if (this.state.mapsLoaded) {
      const maps = this.state.maps || {};
      const map = this.state.map || {};
      let wayLine = this.state.wayLine || null;

      // mapPolyLines

      if (wayLine) {
        wayLine.setPath(coordinates);
        map.setCenter(coordinates[0]);
        map.setZoom(12);
      } else {
        wayLine = new maps.Polyline({
          path: coordinates,
          geodesic: true,
          strokeColor: "black",
          strokeOpacity: 0.6,
          strokeWeight: 5,
          icons: [
            {
              icon: {
                path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                scale: 5,
                strokeColor: "green",
                title: "Start"
              },
              offset: "100%"
            },
            {
              icon: {
                path: window.google.maps.SymbolPath.CIRCLE,
                scale: 8,
                strokeColor: "orange",
                title: "end"
              },
              offset: "0%"
            }
          ],
          map: map
        });

        map.setCenter(coordinates[0]);
        map.setZoom(12);

        this.setState({ wayLine });
      }
    }
  }

  handleSeekBarChange = newValue => {
    try {
      const coordinates = this.state.coordinates || [];
      const mapPolyLines = this.state.mapPolyLines || [];
      const newPoints = coordinates.slice(0, newValue);

      const mapMarker = this.state.mapMarker || null;

      for (let index = 0; index < mapPolyLines.length; index++) {
        const points = newPoints.filter(el => el.lineId === index);
        if (mapPolyLines[index]) {
          mapPolyLines[index].setPath(points);
        }
      }

      if (mapMarker && newPoints.length) {
        if (newPoints.length) {
          mapMarker.setPosition(newPoints[newPoints.length - 1]);
        } else {
          if (coordinates.length) {
            mapMarker.setPosition(coordinates[0]);
          }
        }
      }
    } catch (error) {
      console.log("err ", error);
    }

    /*
    let wayLine = this.state.wayLine || null;
    if (wayLine) {
      wayLine.setPath(coordinates.slice(0, newValue));
    }

    */

    this.setState({ seekBarValue: newValue });
  };

  playNextFrame = () => {
    if (this.state.seekBarValue === this.state.seekBarMax) {
      clearInterval(this.intervalId);
    } else {
      this.handleSeekBarChange(this.state.seekBarValue + 1);
    }
  };

  handleOnPlay = () => {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    } else {
      if (this.state.seekBarValue === this.state.seekBarMax) {
        this.setState({ seekBarValue: 0 });
      }
      this.state.map.setCenter(this.state.coordinates[this.state.seekBarValue]);
      this.state.map.setZoom(14);

      this.intervalId = setInterval(this.playNextFrame, this.state.playSpeed);
    }
  };

  handleOnFocus = () => {
    const coordinates = this.state.coordinates || [];
    const map = this.state.map || {};

    const seekBarValue = this.state.seekBarValue || 0;

    map.setCenter(coordinates[seekBarValue]);
  };

  handleOnPlaySpeedChange = type => {
    if (type === "slow" && this.state.playSpeed < 1400) {
      this.setState({ playSpeed: this.state.playSpeed + 200 });
    } else if (type === "fast" && this.state.playSpeed > 200) {
      this.setState({ playSpeed: this.state.playSpeed - 200 });
    } else if (type === "reset") {
      this.setState({ playSpeed: 200 });
    }

    if (this.intervalId) {
      clearInterval(this.intervalId);

      setTimeout(() => {
        this.intervalId = setInterval(this.playNextFrame, this.state.playSpeed);
      }, 10);
    }
  };

  renderCurrentPoint(newValue) {
    try {
      let x = this.state.wayPoints[newValue];

      if (!x && this.state.wayPoints.length === newValue) {
        x = this.state.wayPoints[newValue];
        x.speed = 0;
      }

      const time = x.updatetime;
      const speed = x.speed;

      return (
        <h3>
          Time {AppHelperDateAndTimeToString(time)}| Speed {speed}kmph
        </h3>
      );
    } catch {
      return <h3> - </h3>;
    }
  }

  onGeofenceSelect = item => {
    MapPolygon.toggle(item.autoid);
  };

  render() {
    
    const vehicle = this.props.vehicle || {};
    const itemObject = this.props.itemObject || {};

    const userDetails = this.props.userDetails || {};
    const subscriberDetails = this.props.subscriberDetails || {} ;

    const logoImage =
      subscriberDetails && subscriberDetails.logo_image
        ? `${apiTaskAppPath.CDN_IMAGE_URL}${subscriberDetails.logo_image}`
        : "";

    
    return (
      <Card>
        <CardBody>
        <Row >
        <Colxx xxs="12">
          
          
          <div className="w-100 text-center">
            <span className="float-right">  {logoImage ? <img src={logoImage} alt="" /> : ""} </span>

            <h2> { userDetails.companyName} </h2>
          </div>
          </Colxx>
          <Colxx xxs="4">
            <VehicleCard vehicle={vehicle} />
            <Table responsive hover bordered striped>
              <tbody>
                <tr>
                  <th> First Start </th>
                  <td>
                    {AppHelperDateAndTimeToString(itemObject.first_start)}
                  </td>
                </tr>
                <tr>
                  <th> Last End </th>
                  <td>{AppHelperDateAndTimeToString(itemObject.last_end)}</td>
                </tr>
                <tr>
                  <th> Trips </th>
                  <td> {itemObject.trip_count} </td>
                </tr>
                <tr>
                  <th> Kilometers </th>
                  <td> {itemObject.mileage_sum} km</td>
                </tr>
                <tr>
                  <th> Engin duration </th>
                  <td>
                    {AppHelperSecondsAsHHmm(itemObject.totalduration_sec_sum)}
                    <small> HH:mm </small>
                  </td>
                </tr>
                <tr>
                  <th> Idle duration </th>
                  <td>
                    {AppHelperSecondsAsHHmm(itemObject.idl_sec_sum)} <small> HH:mm </small>
                  </td>
                </tr>
                <tr>
                  <th> Top Speed </th>
                  <td> {this.state.topSpeed.speed} <small>kmph </small> </td>
                </tr>
                <tr>
                  <th> Avg Speed </th>
                  <td> {this.props.avgSpeed} <small> kmph </small> </td>
                </tr>
              </tbody>
            </Table>
          </Colxx>
        
          <Colxx xxs="8">
            <div className={"my-pretty-chart-container"}>
              <Chart
                className="w-100"
                height={"448px"}
                chartType="ComboChart"
                loader={<div>Loading Chart</div>}
                data={this.state.speedChartData}
                options={{
                  title: "Speed chart",
                  vAxis: { title: "Kmph" },
                  hAxis: { title: "Time" },
                  seriesType: "area",
                  series: { 1: { type: "line" } }
                }}
                rootProps={{ "data-testid": "1" }}
              />
            </div>
          </Colxx>
          <Colxx xxs="12">
            <div style={{ height: "848px" }} className="w-100">
              <GoogleMap
                yesIWantToUseGoogleMapApiInternals={true}
                bootstrapURLKeys={{
                  key: "AIzaSyAKjw_wuep7sJmjM6tKylxLr6Yo_bHUxnw",
                  libraries: ["drawing"].join(",")
                }}
                defaultCenter={[25.273217, 51.414257]}
                defaultZoom={10}
                options={{ mapTypeControl: true }}
                onGoogleApiLoaded={({ map, maps }) => {
                  this.onMapLoaded(map, maps);
                }}
              />
            </div>
          </Colxx>
        </Row>
        <div className="text-muted"> www.ctraac.com powered by Cloud Telematics(www.cloudtel.qa) | {AppHelperDateAndTimeToString(new Date())} | {userDetails.firstName} </div>
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = ({ authUser, geofence, subscriber }) => {
  const { user, details } = authUser;
  const { items } = geofence;
  const subscriberDetails = subscriber.details;

  return { user, geofenceAll: items, userDetails : details, subscriberDetails };
};

export default connect(mapStateToProps, {
  geofenceSelectRequest
})(withRouter(HistoryPlaybackPrinter));
