import React from "react";
import { Row, ButtonGroup, DropdownToggle, DropdownMenu, DropdownItem, ButtonDropdown, Button } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { Separator } from "./common/CustomBootstrap";
import DateTimePicker from "react-datetime-picker";
import { Colxx } from "./common/CustomBootstrap";
import IntlMessages from "../helpers/IntlMessages";

import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

const today = moment(new Date());

class DateRangePicker extends React.Component {
  constructor(props) {
    super();
    const startTime = props.startTime || moment(new Date()).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    const endTime =
      props.endTime ||
      moment(new Date()).set({
        hour: 23,
        minute: 59,
        second: 59,
        millisecond: 999,
      });

    this.state = {
      autoSaveRemainingSec: 5,
      startTime: startTime,
      endTime: endTime,
      adjusted: false,
      isOpenSizingXs: false,
      hourLimit: props.hourLimit || null,
    };

    this.autoSaveTimer = null;
  }

  autoSaveTimerRestart = (start) => {
    this.setState({ autoSaveRemainingSec: 5 });
    if (this.autoSaveTimer) {
      clearInterval(this.autoSaveTimer);
    }

    if (start) {
      this.autoSaveTimer = setInterval(this.autoSaveCountDown, 1000);
    }
  };

  autoSaveCountDown = () => {
    const seconds = this.state.autoSaveRemainingSec - 1;
    this.setState({ autoSaveRemainingSec: seconds });

    if (seconds === 0) {
      if (this.autoSaveTimer) {
        clearInterval(this.autoSaveTimer);
      }

      if (this.props.onChange) {
        const { startTime, endTime } = this.state;
        this.props.onChange(startTime, endTime);
      }
    }
  };

  componentDidMount() {
    this.validateAndUpdate(this.state.startTime, this.state.endTime);
  }

  handleChangeStart = (timeValue) => {
    const time = moment(timeValue);
    if (!this.state.adjusted) {
      const suj = moment(time.toDate());
      this.validateAndUpdate(time, suj.endOf("day"));
    } else {
      this.validateAndUpdate(time, this.state.endTime);
    }
  };

  handleChangeEnd = (timeValue) => {
    const time = moment(timeValue);
    this.validateAndUpdate(this.state.startTime, time);
    this.setState({ adjusted: true });
  };

  validateAndUpdate = (startTime, endTime) => {
    this.setState({ startTime, endTime });
    if (this.props.onChange) {
      this.props.onChange(startTime, endTime);
    }
  };

  // if hourLimit is set show the error message
  validateHourLimit = (startTime, endTime) => {
    if (this.state.hourLimit) {
      const diff = endTime.diff(startTime, "hours", true);
      if (diff > this.state.hourLimit) {
        // if diff is greater than 24 hours show the error message in days
        if (diff > 24) {
          return (
            <span className="bad" key="1">
              {`The time range is limited to ${Math.round((this.state.hourLimit || 0) / 24)} days`}
            </span>
          );
        }

        return (
          <span className="bad" key="2">
            {`The time range is limited to ${this.state.hourLimit} hours`}
          </span>
        );
      }
    }
    return null;
  };

  renderSuggestionsMore(suggestions) {
    switch (suggestions) {
      case "months":
        return (
          <React.Fragment>
            <DropdownItem
              key="e7"
              value={7}
              onClick={() => {
                this.validateAndUpdate(
                  today.clone().subtract(3, "days").startOf("day"),
                  today.clone().subtract(3, "days").endOf("day")
                );
              }}
            >
              {today.clone().subtract(3, "days").format("dddd")}
            </DropdownItem>

            <DropdownItem
              key="e8"
              value={8}
              onClick={() => {
                this.validateAndUpdate(
                  today.clone().subtract(4, "days").startOf("day"),
                  today.clone().subtract(4, "days").endOf("day")
                );
              }}
            >
              {today.clone().subtract(4, "days").format("dddd")}
            </DropdownItem>

            <DropdownItem
              key="e9"
              value={6}
              onClick={() => {
                this.validateAndUpdate(
                  today.clone().subtract(5, "days").startOf("day"),
                  today.clone().subtract(5, "days").endOf("day")
                );
              }}
            >
              {today.clone().subtract(5, "days").format("dddd")}
            </DropdownItem>

            <DropdownItem
              key="e10"
              value={7}
              onClick={() => {
                this.validateAndUpdate(
                  today.clone().subtract(6, "days").startOf("day"),
                  today.clone().subtract(6, "days").endOf("day")
                );
              }}
            >
              {today.clone().subtract(6, "days").format("dddd")}
            </DropdownItem>

            <DropdownItem
              key="d1"
              value={1}
              onClick={() => {
                this.validateAndUpdate(today.clone().add(-1, "week"), today.clone());
              }}
            >
              {today.clone().add(-1, "week").format("MMMD")}
              {today.clone().format("-MMMD")}
              <small> This Week </small>
            </DropdownItem>

            <DropdownItem
              key="d2"
              value={2}
              onClick={() => {
                this.validateAndUpdate(today.clone().add(-2, "week"), today.clone().add(-1, "week"));
              }}
            >
              {today.clone().add(-2, "week").format("MMMD")}
              {today.clone().add(-1, "week").format("-MMMD")}
              <small> Last Week </small>
            </DropdownItem>

            <Separator key="s1" />

            <DropdownItem
              key="d3"
              value={3}
              onClick={() => {
                this.validateAndUpdate(
                  today.clone().add(-2, "months").startOf("month"),
                  today.clone().add(-1, "months").endOf("month")
                );
              }}
            >
              {today.clone().add(-2, "months").startOf("month").format("MMMD")}
              {today.clone().add(-1, "months").endOf("month").format("-MMMD")}
            </DropdownItem>

            <DropdownItem
              key="d4"
              value={4}
              onClick={() => {
                this.validateAndUpdate(
                  today.clone().add(-3, "months").startOf("month"),
                  today.clone().add(-1, "months").endOf("month")
                );
              }}
            >
              {today.clone().add(-3, "months").startOf("month").format("MMMD")}
              {today.clone().add(-1, "months").endOf("month").format("-MMMD")}
            </DropdownItem>
          </React.Fragment>
        );
      default:
        return (
          <React.Fragment>
            <DropdownItem
              key="e7"
              value={7}
              onClick={() => {
                this.validateAndUpdate(
                  today.clone().subtract(4, "days").startOf("day"),
                  today.clone().subtract(4, "days").endOf("day")
                );
              }}
            >
              {today.clone().subtract(4, "days").format("dddd")}
            </DropdownItem>

            <DropdownItem
              key="e8"
              value={8}
              onClick={() => {
                this.validateAndUpdate(
                  today.clone().subtract(5, "days").startOf("day"),
                  today.clone().subtract(5, "days").endOf("day")
                );
              }}
            >
              {today.clone().subtract(5, "days").format("dddd")}
            </DropdownItem>

            <DropdownItem
              key="e9"
              value={6}
              onClick={() => {
                this.validateAndUpdate(
                  today.clone().subtract(6, "days").startOf("day"),
                  today.clone().subtract(6, "days").endOf("day")
                );
              }}
            >
              {today.clone().subtract(6, "days").format("dddd")}
            </DropdownItem>

            <DropdownItem
              key="e10"
              value={7}
              onClick={() => {
                this.validateAndUpdate(
                  today.clone().subtract(7, "days").startOf("day"),
                  today.clone().subtract(7, "days").endOf("day")
                );
              }}
            >
              {today.clone().subtract(7, "days").format("dddd")}
            </DropdownItem>

            <DropdownItem
              key="d2"
              value={2}
              onClick={() => {
                this.validateAndUpdate(today.clone().add(-2, "week"), today.clone().add(-1, "week"));
              }}
            >
              {today.clone().add(-2, "week").format("MMMD")}
              {today.clone().add(-1, "week").format("-MMMD")}
              <small> Last Week </small>
            </DropdownItem>

            <Separator key="s1" />

            <DropdownItem
              key="d3"
              value={3}
              onClick={() => {
                this.validateAndUpdate(
                  today.clone().add(-2, "months").startOf("month"),
                  today.clone().add(-1, "months").endOf("month")
                );
              }}
            >
              {today.clone().add(-2, "months").startOf("month").format("MMMD")}
              {today.clone().add(-1, "months").endOf("month").format("-MMMD")}
            </DropdownItem>

            <DropdownItem
              key="d4"
              value={4}
              onClick={() => {
                this.validateAndUpdate(
                  today.clone().add(-3, "months").startOf("month"),
                  today.clone().add(-1, "months").endOf("month")
                );
              }}
            >
              {today.clone().add(-3, "months").startOf("month").format("MMMD")}
              {today.clone().add(-1, "months").endOf("month").format("-MMMD")}
            </DropdownItem>
          </React.Fragment>
        );
    }
  }

  renderSuggestions(suggestions) {
    switch (suggestions) {
      case "months":
        return (
          <tr>
            <td colSpan="2">
              <button
                className="btn btn-link btn-xs"
                onClick={() => {
                  this.validateAndUpdate(
                    moment().startOf("month"),
                    moment().set({
                      hour: 23,
                      minute: 59,
                      second: 59,
                      millisecond: 999,
                    })
                  );
                }}
              >
                {moment().format("MMMM")}
              </button>
              <button
                className="btn btn-link btn-xs"
                onClick={() => {
                  this.validateAndUpdate(
                    moment().subtract(1, "months").startOf("month"),
                    moment().subtract(1, "months").endOf("month")
                  );
                }}
              >
                {moment().subtract(1, "months").startOf("month").format("MMMM")}
              </button>

              <button
                className="btn btn-link btn-xs"
                onClick={() => {
                  this.validateAndUpdate(
                    moment().subtract(2, "months").startOf("month"),
                    moment().subtract(2, "months").endOf("month")
                  );
                }}
              >
                {moment().subtract(2, "months").startOf("month").format("MMMM")}
              </button>

              <button
                className="btn btn-link btn-xs"
                onClick={() => {
                  this.validateAndUpdate(
                    this.state.startTime.clone().add(1, "months").startOf("month"),
                    this.state.endTime.clone().add(1, "months").endOf("month")
                  );
                }}
              >
                <i className="iconsminds-to-top-2" /> Month
              </button>

              <button
                className="btn btn-link btn-xs"
                onClick={() => {
                  this.validateAndUpdate(
                    this.state.startTime.clone().subtract(1, "months").startOf("month"),
                    this.state.endTime.clone().subtract(1, "months").endOf("month")
                  );
                }}
              >
                <i className="iconsminds-to-bottom-2" /> Month
              </button>
            </td>
          </tr>
        );
      default:
        return (
          <tr>
            <td colSpan="2">
              <button
                className="btn btn-link btn-xs"
                onClick={() => {
                  this.validateAndUpdate(
                    this.state.startTime.clone().add(-1, "days"),
                    this.state.endTime.clone().add(-1, "days")
                  );
                }}
              >
                {"-1day"}
              </button>
              <button
                className="btn btn-link btn-xs"
                onClick={() => {
                  this.validateAndUpdate(
                    this.state.startTime.clone().add(1, "days"),
                    this.state.endTime.clone().add(1, "days")
                  );
                }}
              >
                {"+1day"}
              </button>

              <button
                className="btn btn-link btn-xs"
                onClick={() => {
                  this.validateAndUpdate(
                    today.clone().subtract(1, "days").startOf("day"),
                    today.clone().subtract(1, "days").endOf("day")
                  );
                }}
              >
                {today.clone().subtract(1, "days").format("ddd")}
              </button>

              <button
                className="btn btn-link btn-xs"
                onClick={() => {
                  this.validateAndUpdate(
                    today.clone().subtract(2, "days").startOf("day"),
                    today.clone().subtract(2, "days").endOf("day")
                  );
                }}
              >
                {today.clone().subtract(2, "days").format("ddd")}
              </button>

              <button
                className="btn btn-link btn-xs"
                onClick={() => {
                  this.validateAndUpdate(
                    today.clone().subtract(3, "days").startOf("day"),
                    today.clone().subtract(3, "days").endOf("day")
                  );
                }}
              >
                {today.clone().subtract(3, "days").format("ddd")}
              </button>

              <button
                className="btn btn-link btn-xs"
                onClick={() => {
                  this.validateAndUpdate(
                    this.state.startTime.clone().startOf("day"),
                    this.state.endTime.clone().endOf("day")
                  );
                }}
              >
                12:00am-11:59pm
              </button>
            </td>
          </tr>
        );
    }
  }

  toggleSizingXs = () => {
    this.setState((prevState) => ({
      isOpenSizingXs: !prevState.isOpenSizingXs,
    }));
  };

  render() {
    const format = this.props.dateOnly ? "y-MMM-dd" : "y-MMM-dd h:mma";

    if (this.props.singleDay) {
      return (
        <React.Fragment>
          <Row>
            <Colxx xl="6" lg="12" md="12" sm="12">
              <DateTimePicker
                onChange={this.handleChangeStart}
                value={this.state.startTime.toDate()}
                format={format}
                clearIcon={null}
              />
            </Colxx>
          </Row>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <ButtonGroup className="flex-wrap mr-2">
          <Button
            outline
            size="xs"
            onClick={() => {
              this.validateAndUpdate(today.clone().startOf("day"), today.clone().endOf("day"));
            }}
          >
            Today
          </Button>

          <Button
            outline
            size="xs"
            onClick={() => {
              this.validateAndUpdate(today.clone().add(-7, "days").startOf("day"), today.clone().endOf("day"));
            }}
          >
            7day
          </Button>

          <Button
            outline
            size="xs"
            onClick={() => {
              this.validateAndUpdate(today.clone().add(-14, "days").startOf("day"), today.clone().endOf("day"));
            }}
          >
            14day
          </Button>

          <Button
            outline
            size="xs"
            onClick={() => {
              this.validateAndUpdate(today.clone().add(-30, "days").startOf("day"), today.clone().endOf("day"));
            }}
          >
            30day
          </Button>

          <ButtonDropdown className="bd-container" isOpen={this.state.isOpenSizingXs} toggle={this.toggleSizingXs}>
            <DropdownToggle caret size="xs" outline color="secondary">
              <IntlMessages id="tracking.more" />
            </DropdownToggle>
            <DropdownMenu
              modifiers={{
                setMaxHeight: {
                  enabled: true,
                  order: 890,
                  fn: (data) => {
                    return {
                      ...data,
                      styles: {
                        ...data.styles,
                        overflow: "auto",
                        maxHeight: 221,
                      },
                    };
                  },
                },
              }}
            >
              {this.renderSuggestionsMore(this.props.suggestions)}
            </DropdownMenu>
          </ButtonDropdown>
        </ButtonGroup>

        <Row>
          <Colxx xl="12" lg="12" md="12" sm="12">
            <DateTimePicker
              onChange={this.handleChangeStart}
              value={this.state.startTime.toDate()}
              format={format}
              clearIcon={null}
            />

            <DateTimePicker
              onChange={this.handleChangeEnd}
              value={this.state.endTime.toDate()}
              format={format}
              clearIcon={null}
            />
          </Colxx>
        </Row>

        <table className="w100">
          <tbody>
            <tr>
              <td colSpan="2">
                {this.state.startTime.isSameOrAfter(this.state.endTime) ? (
                  <span ke="2" className="bad">
                    {" "}
                    Date range in valid{" "}
                  </span>
                ) : (
                  ""
                )}
                {this.validateHourLimit(this.state.startTime, this.state.endTime)}
              </td>
            </tr>
            {this.renderSuggestions(this.props.suggestions)}
          </tbody>
        </table>
      </React.Fragment>
    );
  }
}

export default DateRangePicker;
