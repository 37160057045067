import React, { Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Badge, ButtonGroup, Button, Row, CustomInput } from "reactstrap";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import GoogleMap from "google-map-react";
import { Colxx } from "./common/CustomBootstrap";
import { SliderTooltip } from "../components/common/SliderTooltips";
import { geofenceSelectRequest } from "../redux/actions";
import { favoritePlacesSelectRequest } from "../redux/actions";

import { AppHelperDateAndTimeToString } from "../helpers/AppHelper";

import MapDrawing from "../map-helper/map-drawing";
import MapPolygon from "../map-helper/map-polygon";
import MapInfo from "../map-helper/map-info";
import MapFavoritePlaces from "../map-helper/map-favorite-places";

import {
  GeofenceAdd,
  GeofenceUpdate,
  GeofenceDelete,
} from "../api-tasks/geofence-changes";

import {
  FavoritePlaceAdd,
  FavoritePlacesDelete
} from "../api-tasks/favorite-places";

import { NotificationManager } from "../components/common/react-notifications";
import IntlMessages from "../helpers/IntlMessages";

/*
const FORWARD_CLOSED_ARROW = {
  icon: {
    path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
    scale: 5,
    strokeColor: "green",
    title: "Start"
  },
  offset: "100%"
};

const SYMBOL_PATH_CIRCLE = {
  icon: {
    path: window.google.maps.SymbolPath.CIRCLE,
    scale: 8,
    strokeColor: "orange",
    title: "end"
  },
  offset: "0%"
}*/

const LOCAL_STORAGE_HISTORY_FOCUSED = "user_history_focus";
const VEHICLE_ICON_RUNNING = "/map-assets/pickup_running.png";
const VEHICLE_ICON_IDLE = "/map-assets/red-dot.png";

const sliderHandle = (props) => {
  const { value, dragging, index, offset, ...restProps } = props;
  const positionStyle = {
    backgroundColor: "#red !important",
  };
  return (
    <Fragment key={index}>
      <Slider.Handle
        className="btn btn-secondary btn-xs bg-primary"
        style={positionStyle}
        value={value}
        offset={offset}
        {...restProps}
      />
    </Fragment>
  );
};

function distance(lat1, lon1, lat2, lon2, unit) {
  if (lat1 === 0 || lat2 === 0 || lon1 === 0 || lon2 === 0) {
    return 0;
  }

  if (lat1 === lat2 && lon1 === lon2) {
    return 0;
  } else {
    var radlat1 = (Math.PI * lat1) / 180;
    var radlat2 = (Math.PI * lat2) / 180;
    var theta = lon1 - lon2;
    var radtheta = (Math.PI * theta) / 180;
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit === "K") {
      dist = dist * 1.609344;
    }
    if (unit === "N") {
      dist = dist * 0.8684;
    }
    return dist;
  }
}

class HistoryPlayback extends React.Component {
  constructor(props) {
    super(props);
    const lsFocusted = localStorage.getItem(LOCAL_STORAGE_HISTORY_FOCUSED);
    const userHistoryFocus = lsFocusted === null || lsFocusted === "true";

    this.state = {
      selectedVehicle: undefined,
      mapsLoaded: false,
      map: null,
      maps: null,
      locationControlVisible: false,
      wayPoints: [],
      coordinates: [],
      geofencePoints: [],
      favoritePlacePoints: [],
      seekBarMin: 0,
      seekBarMax: 0,
      seekBarValue: 0,
      topSpeed: {},
      playSpeed: 200,
      mapPolyLines: [],
      geofenceVisibility: true,
      favoritePlacesVisibility: true,
      fileName: "Ctraac - Tracking Rows.csv",
      stickers: [],
      eventsOnMap: true,
      focusRequsted: userHistoryFocus,
      vehicleIcon: "running",
    };

    this.intervalId = null;
  }

  componentWillUnmount() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  componentDidMount() {
    if (this.props.wayPoints && this.props.wayPoints.length) {
      this.onWayPointsChange(this.props.wayPoints);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.wayPoints !== this.props.wayPoints) {
      this.onWayPointsChange(this.props.wayPoints);

      // if line was there
      if (!this.props.wayPoints.length && this.state.wayLine) {
        this.state.wayLine.setPath([]);
      }
    }

    if (prevProps.stickers !== this.props.stickers) {
      this.setState({ stickers: this.props.stickers });
    }

    if (prevProps.geofenceAll !== this.props.geofenceAll) {
      this.generateGeofencePoints();
    }

    if (prevProps.favoritePlacesAll !== this.props.favoritePlacesAll) {
      this.generateFavoritePlaces();
    }
  }

  onFavoritePlaceDelete = (id) => {
    const user = this.props.user;
    FavoritePlacesDelete(user.userToken, id).then((response) => {
      if (response) {
        this.setState({ loading: false });
        if (response) {
          if (response.status === "success") {
            this.props.favoritePlacesSelectRequest(this.props.history);
            NotificationManager.success(
              response.message,
              "Success",
              3000,
              null,
              null,
              ""
            );
          } else {
            NotificationManager.warning(
              response.message,
              response.status,
              5000,
              null,
              null,
              ""
            );
          }
        }
      }
    });
  };


  onGeofenceDelete = (id) => {
    const user = this.props.user;

    const userDetails = this.props.userDetails;

    if(userDetails.roleText === "User" ){

      NotificationManager.error(
        'Geofence can delete only by Owner or Admin',
        "Permission denied",
        5000,
        null,
        null,
        ""
      );
      return;
    }

    GeofenceDelete(user.userToken, id).then((response) => {
      if (response) {
        this.setState({ loading: false });
        if (response) {
          if (response.status === "success") {
            this.props.geofenceSelectRequest(this.props.history);
            NotificationManager.success(
              response.message,
              "Success",
              3000,
              null,
              null,
              ""
            );
          } else {
            NotificationManager.warning(
              response.message,
              response.status,
              5000,
              null,
              null,
              ""
            );
          }
        }
      }
    });
  };

  onGeofenceUpdate = (id, title, speed) => {
    const user = this.props.user;
    const userDetails = this.props.userDetails;

    if(userDetails.roleText === "User" ){

      NotificationManager.error(
        'Geofence can update only by Owner or Admin',
        "Permission denied",
        5000,
        null,
        null,
        ""
      );
      return;
    }

    GeofenceUpdate(user.userToken, id, title, speed).then((response) => {
      if (response) {
        this.setState({ loading: false });
        if (response) {
          if (response.status === "success") {
            this.props.geofenceSelectRequest(this.props.history);
            NotificationManager.success(
              response.message,
              "Success",
              3000,
              null,
              null,
              ""
            );
          } else {
            NotificationManager.warning(
              response.message,
              response.status,
              5000,
              null,
              null,
              ""
            );
          }
        }
      }
    });
  };

  generateGeofencePoints = () => {

    const userDetails = this.props.userDetails || {};
    const userRole = userDetails.role || 3;

    // if user no geofence 
    if (userRole === 3) {
      return ; 
    }
    
    if (!this.state.map || !this.state.maps || !this.props.geofenceAll) {
      this.setState({ geofencePoints: [] });
      return;
    }

    const geofenceAll = this.props.geofenceAll || [];
    MapPolygon.updatePolygons(
      geofenceAll,
      this.state.map,
      this.state.maps,
      this.onGeofenceUpdate,
      this.onGeofenceDelete
    );

    this.setState({ geofencePoints: geofenceAll });
  };

  generateFavoritePlaces = () => {
    if (!this.state.map || !this.state.maps || !this.props.favoritePlacesAll) {
      this.setState({ favoritePlacePoints: [] });
      return;
    }

    const favoritePlacesAll = this.props.favoritePlacesAll || [];

    MapFavoritePlaces.updateMarkers(
      favoritePlacesAll,
      this.state.map,
      this.state.maps,
      this.onFavoritePlaceDelete
    );

    this.setState({ favoritePlacePoints: favoritePlacesAll });
  };

  onMapLoaded(map, maps) {
    this.setState({
      mapsLoaded: true,
      map: map,
      maps: maps,
    });

    if (this.props.wayPoints && this.props.wayPoints.length) {
      this.onWayPointsChange(this.props.wayPoints);
    }

    this.mapDrawing = MapDrawing.init(
      map,
      maps,
      this.OnMapDrawingCompleted,
      this.OnMapDrawingAddNew,
      this.OnMapDrawingAddNew_FavoritePlace
    );
    
    this.MapInfo = MapInfo(map, maps);

    // this.props.geofenceSelectRequest(this.props.history);
    this.generateGeofencePoints();
    this.generateFavoritePlaces();
  }

  OnMapDrawingCompleted = (pathPoints) => {
    console.log("log ", this.props.geofence);
  };

  OnMapDrawingAddNew = (name, speed, coordinates) => {
    const points = coordinates.join("");
    const user = this.props.user;
    const userDetails = this.props.userDetails;

    if(userDetails.roleText === "User" ){

      NotificationManager.error(
        'Geofence can add only by Owner or Admin',
        "Permission denied",
        5000,
        null,
        null,
        ""
      );
      return;
    }

    GeofenceAdd(user.userToken, name, points, speed).then((response) => {
      if (response) {
        this.setState({ loading: false });
        if (response) {
          if (response.status === "success") {
            this.props.geofenceSelectRequest(this.props.history);

            NotificationManager.success(
              response.message,
              "Success",
              3000,
              null,
              null,
              ""
            );
          } else {
            NotificationManager.warning(
              response.message,
              response.status,
              5000,
              null,
              null,
              ""
            );
          }
        }
      }
    });
  };

  OnMapDrawingAddNew_FavoritePlace = (title, geo, radius, typeText, type) => {
    // const points = geo;
    const user = this.props.user;
    FavoritePlaceAdd(user.userToken, title, geo, radius, typeText, type).then((response) => {
      if (response) {
        this.setState({ loading: false });

        if (response) {
          if (response.status === "success") {
            this.props.favoritePlacesSelectRequest(this.props.history);

            NotificationManager.success(
              response.message,
              "Success",
              3000,
              null,
              null,
              ""
            );
          } else {
            NotificationManager.warning(
              response.message,
              response.status,
              5000,
              null,
              null,
              ""
            );
          }
        }
      }
    });
  };


  onWayPointsChange = (wayPoints) => {
    this.setState({ wayPoints });
    this.setState({
      wayPoints,
      seekBarMax: 0,
      seekBarValue: 0,
    });

    if (wayPoints && !wayPoints.length) return;

    let km = 0;
    let lineId = 0;
    let color = "black";
    let isSpeeding = false;

    const propsVehicle = this.props.vehicle || {};
    const propsVehicleValue = propsVehicle.value || {};
    const speedLimit = parseInt(propsVehicleValue.speed_limit, 10) || 120;

    const coordinates = [];

    const firstOne = wayPoints[0];
    coordinates.push({
      lat: parseFloat(firstOne.lat),
      lng: parseFloat(firstOne.log),
      distance: "0.00",
      km: 0,
      lineId,
      speed: firstOne.speed,
      color: color,
      updatetime: firstOne.updatetime,
      fuel: firstOne.fuel,
    });

    for (let index = 1; index < wayPoints.length; index++) {
      const x = wayPoints[index];
      const prev = wayPoints[index - 1] || {};

      const kmDiff = distance(prev.lat, prev.log, x.lat, x.log, "K");

      lineId = km + kmDiff - km > 11 ? lineId + 1 : lineId;
      km = km + (kmDiff > 10 ? 0 : kmDiff || 0);

      if (isSpeeding) {
        if (x.speed <= speedLimit) {
          coordinates.push({
            lat: parseFloat(x.lat),
            lng: parseFloat(x.log),
            distance: km.toFixed(2),
            km,
            lineId,
            speed: x.speed,
            color: color,
            updatetime: x.updatetime,
            fuel: x.fuel,
          });

          isSpeeding = false;
          lineId = lineId + 1;
          color = "black";
        }
      } else {
        if (x.speed > speedLimit) {
          isSpeeding = true;
          lineId = lineId + 1;
          color = "red";

          coordinates.push({
            lat: parseFloat(prev.lat),
            lng: parseFloat(prev.log),
            distance: km.toFixed(2),
            km,
            lineId,
            speed: prev.speed,
            color: color,
            updatetime: prev.updatetime,
            fuel: prev.fuel,
          });
        }
      }

      coordinates.push({
        lat: parseFloat(x.lat),
        lng: parseFloat(x.log),
        distance: km.toFixed(2),
        km,
        lineId,
        speed: x.speed,
        color: color,
        updatetime: x.updatetime,
        fuel: x.fuel,
      });
    }

    // this.updateWayLine(coordinates);

    this.updateMapPolyLines(coordinates, lineId);

    const topSpeed = wayPoints.length
      ? wayPoints.reduce(function (prev, current) {
        return prev.speed > current.speed ? prev : current;
      })
      : {};

    this.setState({
      seekBarMax: coordinates.length - 1,

      coordinates,
      seekBarValue: 0,
      topSpeed,
    });

    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }

    if (this.MapInfo) {
      this.MapInfo.loadMarkers(this.props.stickers);
    }
  };

  updateMapPolyLines(coordinates, lines) {
    if (!this.state.mapsLoaded) {
      return;
    }

    const maps = this.state.maps || {};
    const map = this.state.map || {};
    let mapPolyLines = this.state.mapPolyLines || [];

    for (let index = 0; index < mapPolyLines.length; index++) {
      if (mapPolyLines[index]) {
        mapPolyLines[index].setPath([]);
      }
    }

    mapPolyLines = [];

    for (let index = 0; index <= lines; index++) {
      const points = coordinates.filter((el) => el.lineId === index);

      if (points.length) {
        const aItem = new maps.Polyline({
          path: points,
          geodesic: true,
          strokeColor: points[0].color,
          strokeOpacity: 0.6,
          strokeWeight: 5,
          map: map,
        });

        mapPolyLines.push(aItem);
      }
    }

    if (coordinates.length) {
      map.setCenter(coordinates[0]);
    }

    const mapMarker =
      this.state.mapMarker ||
      new maps.Marker({
        id: "h1",
        icon: VEHICLE_ICON_RUNNING,
        map: map,
      });

    this.setState({ mapPolyLines, mapMarker });
  }

  updateWayLine(coordinates) {
    if (this.state.mapsLoaded) {
      const maps = this.state.maps || {};
      const map = this.state.map || {};
      let wayLine = this.state.wayLine || null;

      // mapPolyLines

      if (wayLine) {
        wayLine.setPath(coordinates);
        map.setCenter(coordinates[0]);
        map.setZoom(12);
      } else {
        wayLine = new maps.Polyline({
          path: coordinates,
          geodesic: true,
          strokeColor: "black",
          strokeOpacity: 0.6,
          strokeWeight: 5,
          icons: [
            {
              icon: {
                path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                scale: 5,
                strokeColor: "green",
                title: "Start",
              },
              offset: "100%",
            },
            {
              icon: {
                path: window.google.maps.SymbolPath.CIRCLE,
                scale: 8,
                strokeColor: "orange",
                title: "end",
              },
              offset: "0%",
            },
          ],
          map: map,
        });

        map.setCenter(coordinates[0]);
        map.setZoom(12);

        this.setState({ wayLine });
      }
    }
  }

  handleSeekBarChange = (newValue) => {
    const focusRequsted = this.state.focusRequsted;
    try {
      const coordinates = this.state.coordinates || [];
      const mapPolyLines = this.state.mapPolyLines || [];
      const newPoints = coordinates.slice(0, newValue);

      const mapMarker = this.state.mapMarker || null;
      const map = this.state.map || {};

      for (let index = 0; index < mapPolyLines.length; index++) {
        const points = newPoints.filter((el) => el.lineId === index);
        if (mapPolyLines[index]) {
          mapPolyLines[index].setPath(points);
        }
      }

      if (mapMarker && newPoints.length) {
        const currentPoint = newPoints[newPoints.length - 1];
        if (newPoints.length) {
          mapMarker.setPosition(currentPoint);

          if (this.state.vehicleIcon === "running" && !currentPoint.speed) {
            mapMarker.setIcon(VEHICLE_ICON_IDLE);
            this.setState({ vehicleIcon: "idle" });
          }

          if (this.state.vehicleIcon === "idle" && currentPoint.speed) {
            mapMarker.setIcon(VEHICLE_ICON_RUNNING);
            this.setState({ vehicleIcon: "running" });
          }

          if (focusRequsted && !(newPoints.length % 10)) {
            map.setCenter(currentPoint);
          }
        } else {
          if (coordinates.length) {
            mapMarker.setPosition(coordinates[0]);
          }
        }
      }
    } catch (error) {
      console.log("err ", error);
    }

    /*
    let wayLine = this.state.wayLine || null;
    if (wayLine) {
      wayLine.setPath(coordinates.slice(0, newValue));
    }

    */

    this.setState({ seekBarValue: newValue });
  };

  playNextFrame = () => {
    if (this.state.seekBarValue === this.state.seekBarMax) {
      clearInterval(this.intervalId);
    } else {
      this.handleSeekBarChange(this.state.seekBarValue + 1);
    }
  };

  handleOnPlay = () => {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    } else {
      if (this.state.seekBarValue === this.state.seekBarMax) {
        this.setState({ seekBarValue: 0 });
      }
      this.state.map.setCenter(this.state.coordinates[this.state.seekBarValue]);
      this.state.map.setZoom(14);

      this.intervalId = setInterval(this.playNextFrame, this.state.playSpeed);
    }
  };

  handleOnFocus = () => {
    const coordinates = this.state.coordinates || [];
    const map = this.state.map || {};

    const seekBarValue = this.state.seekBarValue || 0;

    map.setCenter(coordinates[seekBarValue]);

    const focusState = this.state.focusRequsted;
    localStorage.setItem(LOCAL_STORAGE_HISTORY_FOCUSED, !focusState);

    this.setState({ focusRequsted: !focusState });
  };

  handleOnPlaySpeedChange = (type) => {
    if (type === "slow" && this.state.playSpeed < 1400) {
      this.setState({ playSpeed: this.state.playSpeed + 200 });
    } else if (type === "fast" && this.state.playSpeed > 200) {
      this.setState({ playSpeed: this.state.playSpeed - 200 });
    } else if (type === "reset") {
      this.setState({ playSpeed: 200 });
    }

    if (this.intervalId) {
      clearInterval(this.intervalId);

      setTimeout(() => {
        this.intervalId = setInterval(this.playNextFrame, this.state.playSpeed);
      }, 10);
    }
  };

  renderCurrentPoint(newValue) {
    try {
      let x = this.state.coordinates[newValue];

      if (!x && this.state.coordinates.length === newValue) {
        x = this.state.coordinates[newValue];
        x.speed = 0;
      }

      const time = x.updatetime;
      const speed = x.speed;
      const fuel = x.fuel;

      return (
        <h3>
          Time {AppHelperDateAndTimeToString(time)}| Speed {speed}kmph{" "}
          {fuel > 0 ? " | " + fuel + "ltr" : ""}
        </h3>
      );
    } catch {
      return <h3> - </h3>;
    }
  }

  onGeofenceSelect = (item) => {
    MapPolygon.toggle(item.autoid);
  };

  onFavoritePlacesSelect = (item) => {
    MapFavoritePlaces.toggle(item.autoid, true);
  };

  onGeofenceSelectAll = (visibility) => {
    MapPolygon.showAll(visibility);
  };

  onFavoritePlacesSelectAll = (visibility) => {
    MapFavoritePlaces.showAll(visibility);
  };

  render() {
    const favoritePlacesAll = this.state.favoritePlacePoints || [];
    const geofenceAll = this.state.geofencePoints || [];
    const mapPosition = this.state.coordinates[this.state.seekBarValue] || "-";

    return (
      <React.Fragment>
        <Row className={this.state.mapsLoaded ? "" : "wait"}>
          <Colxx xxs="12">
            <Row className="mb-1">
              <Colxx xxs="12">
                <div className="float-right">
                  <CustomInput
                    type="checkbox"
                    id="exCustomInline_6tKylxL"
                    label={`Favorite Places`}
                    checked={this.state.favoritePlacesVisibility}
                    inline
                    onChange={(e) => {

                      this.onFavoritePlacesSelectAll(e.target.checked);
                    this.setState({
                      favoritePlacesVisibility: e.target.checked,
                    });

                       
                    }}
                  />

                  <CustomInput
                    type="checkbox"
                    id="exCustomInline_6tKylxL"
                    label={<IntlMessages id="tracking.events" />}
                    checked={this.state.eventsOnMap}
                    inline
                    onChange={(e) => {
                      if (e.target.checked) {
                        this.MapInfo.loadMarkers(this.props.stickers);
                      } else {
                        this.MapInfo.loadMarkers([]);
                      }

                      this.setState({ eventsOnMap: e.target.checked });
                    }}
                  />

                  <Badge color="info" className="d-inline-block mr-2">
                    Speed x{this.state.playSpeed}
                  </Badge>
                </div>
                {this.renderCurrentPoint(this.state.seekBarValue)}
              </Colxx>
            </Row>
            <Row>
              <Colxx xxs="12">
                <div style={{ height: "448px" }} className="w-100">
                  <GoogleMap
                    yesIWantToUseGoogleMapApiInternals={true}
                    bootstrapURLKeys={{
                      key: "AIzaSyAKjw_wuep7sJmjM6tKylxLr6Yo_bHUxnw",
                      libraries: ["drawing"].join(","),
                    }}
                    defaultCenter={[25.273217, 51.414257]}
                    defaultZoom={10}
                    options={{ mapTypeControl: true }}
                    onGoogleApiLoaded={({ map, maps }) => {
                      this.onMapLoaded(map, maps);
                    }}
                  ></GoogleMap>
                </div>
              </Colxx>
              <Colxx xxs="12" sm="6">
                <SliderTooltip
                  min={0}
                  max={this.state.seekBarMax}
                  value={this.state.seekBarValue}
                  defaultValue={0}
                  className="border p-2"
                  onChange={this.handleSeekBarChange}
                  handle={sliderHandle}
                />
              </Colxx>
              <Colxx xxs="12" sm="6">
                <ButtonGroup className="flex-wrap p-1">
                  <Button
                    outline
                    color="primary"
                    size="xs"
                    onClick={this.handleOnPlay}
                  >
                    <i className="simple-icon-control-play"></i>{" "}
                    <IntlMessages id="tracking.play" />
                  </Button>
                  <Button
                    outline={!this.state.focusRequsted}
                    color="primary"
                    size="xs"
                    onClick={this.handleOnFocus}
                  >
                    <i className="simple-icon-magnifier"></i>{" "}
                    <IntlMessages id="tracking.focus" />
                  </Button>

                  <Button
                    outline
                    color="info"
                    size="xs"
                    onClick={() => {
                      this.handleOnPlaySpeedChange("slow");
                    }}
                  >
                    <i className="simple-icon-control-rewind"></i>{" "}
                    <IntlMessages id="tracking.slower" />
                  </Button>

                  <Button
                    outline
                    color="info"
                    size="xs"
                    onClick={() => {
                      this.handleOnPlaySpeedChange("fast");
                    }}
                  >
                    <i className="simple-icon-control-forward"></i>{" "}
                    <IntlMessages id="tracking.faster" />
                  </Button>

                  <Button
                    outline
                    color="info"
                    size="xs"
                    onClick={() => {
                      this.handleOnPlaySpeedChange("reset");
                    }}
                  >
                    <i className="simple-icon-control-start"></i>{" "}
                    <IntlMessages id="tracking.reset" />
                  </Button>
                </ButtonGroup>
              </Colxx>

              {this.state.topSpeed.speed ? (
                <Colxx xl="3" lg="4" sm="3" className="text-muted">
                  Top Speed
                  <p className="lead color-theme-1 mb-1 value">
                    {this.state.topSpeed.speed}
                  </p>
                  <small className="text-muted">
                    {" "}
                    {AppHelperDateAndTimeToString(
                      this.state.topSpeed.updatetime
                    )}{" "}
                  </small>
                </Colxx>
              ) : (
                ""
              )}

              <Colxx xl="3" lg="4" sm="3" className="text-muted">
                <IntlMessages id="tracking.distance" />
                <p className="lead color-theme-1 mb-1 value">
                  {mapPosition.distance}
                </p>
                <small className="text-muted">
                  <IntlMessages id="tracking.km" />
                </small>
              </Colxx>
            </Row>

            <div className="float-right">
              <span>
                <img src="/map-assets/blue-dot.png" alt="red" />{" "}
                <IntlMessages id="tracking.stop" />
                <small>
                  {" "}
                  <IntlMessages id="tracking.speed-zero-less-than-5-min-but-more-than-2-min" />{" "}
                </small>
              </span>

              <span>
                <img src="/map-assets/red-dot2.png" alt="red" />{" "}
                <IntlMessages id="idle" />
                <small>
                  <IntlMessages id="tracking.speed-zero-more-than-5-min" />
                </small>
              </span>
            </div>

            {(geofenceAll.length ? {} : null) && (
              <div className="mt-2 mb-2">
                <strong className="text-muted">
                   
                  <IntlMessages id="common.geofence" />
                </strong>
                <Button
                  className="ml-1"
                  size="xs"
                  onClick={() => {
                    this.onGeofenceSelectAll(this.state.geofenceVisibility);
                    this.setState({
                      geofenceVisibility: !this.state.geofenceVisibility,
                    });
                  }}
                >
                  {this.state.geofenceVisibility ? (
                    <IntlMessages id="tracking.show-all" />
                  ) : (
                    <IntlMessages id="tracking.hide-all" />
                  )}
                </Button>
                <br />
                {geofenceAll.map((item, index) => {
                  return (
                    <Button
                      outline
                      color="primary"
                      size="xs"
                      key={index}
                      className="mr-1 mt-1"
                      onClick={() => this.onGeofenceSelect(item)}
                    >
                      {item.title}
                    </Button>
                  );
                })}
              </div>
            )}

            {(favoritePlacesAll.length ? {} : null) && (
              <div className="mt-2 mb-2">
                <strong className="text-muted">
                   
                  Favorite Places
                </strong>
                <Button
                  className="ml-1"
                  size="xs"
                  onClick={() => {
                    this.onFavoritePlacesSelectAll(this.state.favoritePlacesVisibility);
                    this.setState({
                      favoritePlacesVisibility: !this.state.favoritePlacesVisibility,
                    });
                  }}
                >
                  {this.state.favoritePlacesVisibility ? (
                    <IntlMessages id="tracking.show-all" />
                  ) : (
                    <IntlMessages id="tracking.hide-all" />
                  )}
                </Button>
                <br />
                {favoritePlacesAll.map((item, index) => {
                  return (
                    <Button
                      outline
                      color="primary"
                      size="xs"
                      key={index}
                      className="mr-1 mt-1"
                      onClick={() => this.onFavoritePlacesSelect(item)}
                    >
                      {item.title}
                    </Button>
                  );
                })}
              </div>
            )}
          </Colxx>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ authUser, geofence, favoritePlaces }) => {
  const { user, details } = authUser;
  const { items } = geofence;
  const favoritePlacesAll = favoritePlaces.items || [];
  return { user, geofenceAll: items, favoritePlacesAll, userDetails: details };
};

export default connect(mapStateToProps, {
  geofenceSelectRequest,
  favoritePlacesSelectRequest,
})(withRouter(HistoryPlayback));
