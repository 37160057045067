import React, { Component } from "react";
import { Button, Row, Card, CardBody } from "reactstrap";
import { connect } from "react-redux";
import ReactToPrint from "react-to-print";

import { Colxx } from "../../components/common/CustomBootstrap";
import VehicleSelect from "../../components/VehicleSelect";
import { authApiAccessCheck } from "../../redux/actions";
import { VehiclesGetDaySummary } from "../../api-tasks-analytics/vehicles";
import { IgnitionGetSuggestionRewind } from "../../api-tasks/ignition";
import DateRangePicker from "../../components/DateRangePicker";
import HistoryPlayback from "../../components/HistoryPlayback";
import CanvasJS from "../../components/CanvasJS";
import { NotificationManager } from "../../components/common/react-notifications";

import {
  AppHelperDateToDBString,
  AppHelperDurationAsHHmm,
} from "../../helpers/AppHelper";
import moment from "moment";
import FuelChart from "../../components/apexcharts/FuelChart";
import IntlMessages from "../../helpers/IntlMessages";

function geo2LatLng(geo) {
  const splits = geo.split(",");
  return {
    lat: parseFloat(splits[0]),
    lng: parseFloat(splits[1]),
  };
}

class History extends Component {
  constructor(props) {
    super();
    this.state = {
      selectedVehicle: null,
      loading: false,
      wayPoints: [],
      suggestions: [],
      stickers: [],
    };
  }

  componentWillUnmount() { }

  componentDidMount() {
    if(this.props.clickGo){
      this.props.clickGo(this.handleGoClick);
    }
  }

  handleGoClick = ({ selectedVehicle, startTime, endTime }) => {


    this.setState({
      startTime: startTime,
      endTime: endTime,
      selectedVehicle: selectedVehicle,
      wayPoints: []
    });

    

    setTimeout(() => {
      this.onDateRangeGo();
    }, 10);

  }

  handleChangeVehicle = (selectedVehicle) => {
    this.setState({ selectedVehicle: selectedVehicle, wayPoints: [] });

    setTimeout(() => {
      this.getSuggestion();
    }, 10);
  };

  onDateRangeGo = () => {
    
    if (!this.state.selectedVehicle) {
      NotificationManager.error(
        <IntlMessages id="tracking.please-select-vehicle" />,
        <IntlMessages id="common.error" />,
        3000,
        null,
        null,
        ""
      );

      return;
    }

    // validate date range is not more than 48 hours
    if (this.state.endTime.diff(this.state.startTime, 'days') > 32) {
      NotificationManager.error(
        <span>
          History playback is limited to 30 days at once. Please select a different date range.
        </span>
        ,
        <IntlMessages id="common.error" />,
        3000,
        null,
        null,
        ""
      );

      return;
    }

    const user = this.props.user;
    const startTime = AppHelperDateToDBString(this.state.startTime.toDate());
    const endTime = AppHelperDateToDBString(this.state.endTime.toDate());
    const vehicleId = this.state.selectedVehicle.key;

    // const vehicleValue = this.state.selectedVehicle.value | {};
    // const timezone = vehicleValue.timezone || 'Asia/Qatar';

    this.setState({ loading: true });

    const offsetMinutes = null;
    const pathWay = true;

    VehiclesGetDaySummary(
      user.userToken,
      [vehicleId],
      startTime,
      endTime,
      offsetMinutes,
      pathWay
    ).then((response) => {
      let wayPoints = [];
      const stickers = [];
      response.forEach((el) => {
        wayPoints = wayPoints.concat(el.pathWay || []);

        el.stops.forEach((elItem) => {
          const time = moment(elItem.startTime);
          stickers.push({
            ...geo2LatLng(elItem.geo),
            title: "Stop",
            timeText: time.format("DD, hh:mm A"),
            time: new Date(elItem.startTime),
            details: "",
            type: "blue",
          });
        });

        el.idles.forEach((elItem) => {
          const startTime = moment(elItem.startTime);
          const endTime = moment(elItem.endTime);

          stickers.push({
            ...geo2LatLng(elItem.geo),
            title: "Idle",
            timeText: startTime.format("DD, hh:mm A"),
            time: new Date(elItem.startTime),
            details:
              " duration: " +
              AppHelperDurationAsHHmm(startTime.toDate(), endTime.toDate()),
            type: "red",
          });
        });

        el.overSpeeds.forEach((elItem) => {
          const time = moment(elItem.eventTime);
          let details =
            "Seconds: " +
            (elItem.totalSeconds > 15 ? "15+" : elItem.totalSeconds);
          details = details + ", Limit: " + elItem.speedLimit;
          details = details + ", Speed: " + elItem.topSpeed;

          stickers.push({
            ...geo2LatLng(elItem.geo),
            title: "Over speed",
            timeText: time.format("DD, hh:mm A"),
            time: new Date(elItem.eventTime),
            details,
            type: 'yellow'

          });
        });
      });

      const wayPointsTrimmed = wayPoints.filter(
        (x) => x.updatetime >= startTime && x.updatetime <= endTime
      );

      this.setState({ wayPoints: wayPointsTrimmed, stickers, loading: false });

      setTimeout(() => {
        this.getSuggestion();
      }, 300);
    });
    /*
    TrackingBasicGetByTimeRangeOptimisedWithTimeLine(
      user.userToken,
      vehicleId,
      startTime,
      endTime,
      timezone
    ).then((response) => {
      this.setState({ loading: false });
      if (response && Array.isArray(response)) {
        this.setState({ wayPoints: response });
        this.getSuggestion();
      }
    }); */
  };

  getSuggestion = () => {
    const user = this.props.user;
    const vehicleId = this.state.selectedVehicle.key;
    const startTime = AppHelperDateToDBString(this.state.startTime.toDate());
    const limit = 7;

    IgnitionGetSuggestionRewind(
      user.userToken,
      vehicleId,
      startTime,
      limit
    ).then((response) => {
      if (response && Array.isArray(response)) {
        this.setState({ suggestions: response });
      }
    });
  };

  handleChangeDateRange = (startTime, endTime) => {
    this.setState({ startTime, endTime });
  };

  onSuggestionSelect = (item) => {
    this.setState({
      startTime: moment(item.first_start),
      endTime: moment(item.last_end),
    });
    setTimeout(() => {
      this.onDateRangeGo();
    }, 5);
  };

  render() {
    return (
      <React.Fragment>

          <p> View past activity for up to 6 months  </p>
        <Row className={this.props.hideSearch ? "mb-1 d-none" : "mb-1"} >
          <Colxx xxs="12">
            <Card>
              <CardBody className={this.state.loading ? "wait" : ""}>
                <Row>
                  <Colxx xl="4" lg="4" md="6" sm="12">
                    <VehicleSelect onChange={this.handleChangeVehicle} allowExpired={true} />
                  </Colxx>
                  <Colxx xl="5" lg="5" md="6" sm="12">
                    <DateRangePicker onChange={this.handleChangeDateRange} hourLimit={768} />
                  </Colxx>
                  <Colxx xxs="2">
                    <Button
                      className="m-2"
                      color="primary"
                      size="sm"
                      onClick={this.onDateRangeGo}
                    >
                      {this.state.loading ? (
                        <span className="spinner d-inline-block">
                          <span className="bounce1" />
                          <span className="bounce2" />
                          <span className="bounce3" />
                        </span>
                      ) : (
                          <IntlMessages id="tracking.go" />
                        )}
                    </Button>
                  </Colxx>
                </Row>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
        <Row className={this.props.hideSearch ? "mb-1 d-none" : "mb-1"} >
          <Colxx xxs="12">
            {this.state.suggestions.map((x, index) => {
              return (
                <Button
                  outline
                  color="primary"
                  size="xs"
                  key={index}
                  className="mr-1"
                  onClick={() => {
                    this.onSuggestionSelect(x);
                  }}
                >
                  {x.dated} {x.mileage_sum}km
                </Button>
              );
            })}
          </Colxx>
        </Row>
        <Row>
          <Colxx xxs="12" className="mb-2">
            <Card>
              <CardBody>
                <ReactToPrint
                  className="float-right"
                  trigger={() => (
                    <Button color="link" size="xs">
                      <IntlMessages id="tracking.print-this-out" />
                    </Button>
                  )}
                  content={() => this.componentRef}
                />

                <HistoryPlayback
                  wayPoints={this.state.wayPoints}
                  ref={(el) => (this.componentRef = el)}
                  stickers={this.state.stickers}
                  vehicle={this.state.selectedVehicle}
                />
              </CardBody>
            </Card>
          </Colxx>
        </Row>

        {this.state.wayPoints.length ? (
          <React.Fragment>
            <Row className="mb-2">
              <Colxx xxs="12">
                <Card>
                  <CardBody>
                    <CanvasJS
                      wayPoints={this.state.wayPoints}
                      title={"Speed Chart"}
                      selectedVehicle={this.state.selectedVehicle}
                    />
                  </CardBody>
                </Card>
              </Colxx>
            </Row>

            <Row className="mb-2">
              <Colxx xxs="12">
                <Card>
                  <CardBody>
                    <FuelChart
                      user={this.props.user}
                      vehiclesAll={this.props.vehiclesAll}
                      wayPoints={this.state.wayPoints}
                    />
                  </CardBody>
                </Card>
              </Colxx>
            </Row>
          </React.Fragment>
        ) : (
            ""
          )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ vehicles, authUser }) => {
  const { all } = vehicles;
  const { user } = authUser;
  return { vehiclesAll: all, user };
};

export default connect(mapStateToProps, { authApiAccessCheck })(History);